import type { ReactNode } from 'react';
import { useNavigate } from 'react-router';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import type { Block, Inline } from '@contentful/rich-text-types';
import { BLOCKS } from '@contentful/rich-text-types';
import type { TypeArticleCarouselIllustration } from '@types.ts';
import clsx from 'clsx';

import { ArticleCarousel } from '@components/ArticleCarousel.tsx';

import useAnalytics from '@lib/hooks/useAnalytics.ts';
import { getDateNightPath, navigateToLegacyTechnique } from '@lib/links.ts';
import { AnalyticsEvent } from '@root/constants.ts';

export const ArticleCarouselIllustrations = ({
  fields,
}: {
  fields: TypeArticleCarouselIllustration;
}) => {
  const navigate = useNavigate();
  const { analytics } = useAnalytics();

  return (
    <div className={'article-carousel-illustrations'}>
      <div className={'article-x-padding'}>
        <div className={'article-max768-container '}>
          <div className={'article-carousel-illustrations-header'}>
            {documentToReactComponents(fields.header, {
              renderNode: {
                [BLOCKS.PARAGRAPH]: (_node: Block | Inline, children: ReactNode) => children,
              },
            })}
          </div>
        </div>
      </div>
      <ArticleCarousel
        showing={{
          xs: 1,
          sm: 1.8,
          md: 2,
          lg: 2,
        }}
        maxWidthClasses={'max-w-[312px]'}
      >
        {fields.illustrations?.map((illustration, index: number) => {
          return (
            <div key={index} className={'article-carousel-illustrations-content-tile'}>
              <div className={'article-carousel-illustrations-content-tile-text-container'}>
                <div
                  className={clsx(
                    'article-carousel-illustrations-content-tile-heading',
                    illustration.fields.italicizeHeader ? 'italic' : '',
                  )}
                >
                  {illustration.fields.heading}
                </div>
                {illustration.fields.description && (
                  <div className={'article-carousel-illustrations-content-tile-description'}>
                    {documentToReactComponents(illustration.fields.description, {
                      renderNode: {
                        [BLOCKS.PARAGRAPH]: (_node: Block | Inline, children: ReactNode) => (
                          <div>{children}</div>
                        ),
                      },
                    })}
                  </div>
                )}
                {illustration.fields.faq && (
                  <div className={'article-carousel-illustrations-content-tile-faq-container'}>
                    {illustration.fields.faq.map(({ fields }) => {
                      return (
                        <div key={fields.question}>
                          <div className={'article-carousel-illustrations-content-tile-faq-q'}>
                            {fields.question}
                          </div>
                          {documentToReactComponents(fields.answer, {
                            renderNode: {
                              [BLOCKS.PARAGRAPH]: (_node: Block | Inline, children: ReactNode) => (
                                <div
                                  className={'article-carousel-illustrations-content-tile-faq-a'}
                                >
                                  {children}
                                </div>
                              ),
                            },
                          })}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              {(illustration.fields.image?.fields.file.url || illustration.fields.readMore) && (
                <div className={'article-carousel-illustrations-container-image-cta'}>
                  {illustration.fields.image?.fields.file.url && (
                    <div className={'article-carousel-illustrations-content-tile-image-container'}>
                      <div className={'article-carousel-illustrations-content-tile-image'}>
                        <img
                          src={illustration.fields.image?.fields.file.url}
                          width={'100%'}
                          className={'article-carousel-illustrations-content-image'}
                        />
                      </div>
                    </div>
                  )}
                  {illustration.fields.readMoreTechnique && (
                    <div className="article-section-read-more">
                      <div
                        onClick={() =>
                          navigateToLegacyTechnique(
                            illustration.fields.readMoreTechnique.fields.title,
                          )
                        }
                      >
                        Read more about {illustration.fields.readMoreTechnique?.fields.title} {'->'}
                      </div>
                    </div>
                  )}
                  {illustration.fields.tryADateNightGame && (
                    <div className="article-section-read-more">
                      <div
                        onClick={() => {
                          analytics.track({
                            event: AnalyticsEvent.LINK_CLICK,
                            props: { name: illustration.fields.tryADateNightGame?.fields.title },
                          });
                          navigate(getDateNightPath(illustration.fields.tryADateNightGame));
                        }}
                      >
                        Try the {illustration.fields.tryADateNightGame?.fields.title} game {'->'}
                      </div>
                    </div>
                  )}
                  {illustration.fields.readMore && (
                    <div className="article-section-read-more">
                      {documentToReactComponents(illustration.fields.readMore, {
                        renderNode: {
                          [BLOCKS.PARAGRAPH]: (_node: Block | Inline, children: ReactNode) => (
                            <div>{children}</div>
                          ),
                        },
                      })}
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </ArticleCarousel>
    </div>
  );
};
