import React from 'react';
import type { AnalyticsBrowserSettings } from '@segment/analytics-next';
import { AnalyticsBrowser } from '@segment/analytics-next';

interface ISegmentContext {
  analytics: AnalyticsBrowser;
  writeKey: string;
  cdnURL?: string;
}

const SegmentContext = React.createContext<ISegmentContext | undefined>(undefined);

interface ISegmentProvider {
  children?: React.ReactNode;
}

export const SegmentProvider: React.FC<ISegmentProvider> = ({ children }) => {
  const config = {
    writeKey: import.meta.env.VITE_SEGMENT_WRITE_KEY,
    // cdnURL: SEGMENT_CDN_URL,
  } as AnalyticsBrowserSettings;

  const analytics = AnalyticsBrowser.load(config);

  return (
    <SegmentContext.Provider value={{ analytics, ...config }}>{children}</SegmentContext.Provider>
  );
};

// Create an analytics hook that we can use with other components.
export const useSegment = () => {
  const result = React.useContext(SegmentContext);

  if (!result) {
    throw new Error('Context used outside of its Provider!');
  }
  return result;
};
