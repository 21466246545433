import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useInView } from 'react-intersection-observer';
import Lottie from 'react-lottie-player';
import type { TechniqueData } from '@types.ts';
import clsx from 'clsx';

export const AnimatedTechniqueIcon = ({
  src,
  hover: PropHover = false,
  active = false,
  visible,
  playing: PropPlaying = false,
}: {
  src: any;
  hover: boolean;
  active?: boolean;
  visible: boolean;
  playing: boolean;
}) => {
  const [ currFrame, setCurrFrame ] = useState<number>(0);
  const [ playedOnce, setPlayedOnce ] = useState<boolean>(false);
  const [ playing, setPlaying ] = useState<boolean>(false);
  const [ lottieData, setLottieData ] = useState<object>();
  const [ frames, setFrames ] = useState<number>();
  const [ hover, setHover ] = useState<boolean>(false);
  // const [ currFrame, ]

  // const playerRef = useRef<LottieRef>(null);

  useEffect(() => {
    if (typeof PropPlaying !== 'undefined' && !playedOnce) {
      setPlaying(PropPlaying);
    }
  }, [ PropPlaying, playedOnce ]);

  useEffect(() => {
    if (PropHover && playedOnce && !hover && !playing) {
      setCurrFrame(0);
      setPlaying(true);
    }

    setHover(PropHover);
  }, [
    PropHover, hover, playing, playedOnce, 
  ]);

  useEffect(() => {
    const loadAnimation = async () => {
      setFrames(src?.op);
      setLottieData(src);
    };

    if (!lottieData && visible) {
      loadAnimation();
    }
  }, [ visible, lottieData, src ]);

  useEffect(() => {
    if (frames) {
      // playerRef.current?.setSeeker(frames);
    }
  }, [ frames ]);

  useEffect(() => {
    if (active && isMobile) {
      setPlaying(true);
    }
  }, [ active ]);

  if (!lottieData) {
    return null;
  }

  return (
    <Lottie
      style={{ width: '100%', height: '100%' }}
      animationData={lottieData}
      goTo={currFrame}
      play={playing}
      loop={false}
      onComplete={() => {
        setCurrFrame(frames || 0);
        setPlaying(false);
        setPlayedOnce(true);
      }}
      // onLoopComplete={() => {
      //   console.log('AnimatedTechniqueIcon :: Loop Complete -');
      // }}
    />
  );
};

export const TechniqueIcon = ({
  hovered,
  technique,
  animate = true,
}: {
  hovered: boolean;
  technique: TechniqueData['fields'];
  animate?: boolean;
}) => {
  const [ hover, setHover ] = useState<boolean>(false);
  const [ mounted, setMounted ] = useState<boolean>(false);

  const { ref, inView: isInViewport } = useInView({
    threshold: 0,
  });

  useEffect(() => setMounted(true), []);
  useEffect(() => {
    setHover(hovered);
  }, [ hovered ]);
  if (!mounted) return null;

  return (
    <div
      className={clsx('flex flex-col justify-between items-center', 'rounded-lg w-full h-full')}
      ref={ref}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className={'flex flex-col items-center w-full h-full'}>
        {technique.image ? (
          animate && technique.animation ? (
            <div className={'w-full h-full'}>
              <AnimatedTechniqueIcon
                src={technique.animation}
                playing={isInViewport || false}
                active={isMobile ? isInViewport || false : true}
                hover={hover}
                visible={true}
              />
            </div>
          ) : (
            <div className={'w-full h-full'}>
              <img
                src={technique.image?.fields.file.url}
                alt={technique.title}
                style={{ width: '100%', height: '100%' }}
              />
            </div>
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
