export const DEFAULT_TIMEZONE = 'America/New_York (GMT-05:00)';

export const NMH_LOOKUP: {
  [key: string]: string;
} = {
  en: '3UdGX4n0LGJGR7wwU1YdAm',
  de: '6wgeAXRyiexxtTSZf3MIVZ',
};

export const PAGES_LOOKUP: {
  [key: string]: string;
} = {
  gift: 'qotkeBXbYIRiN9eviBhhU',
};
