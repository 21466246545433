import type { AuthSession, GetCurrentUserOutput, JWT } from 'aws-amplify/auth';

import type { AuthenticatedUserData, AuthOperation } from './amplify.types';

export const mapAuthErrorOperation = (error: unknown) => {
  if (error instanceof Error) {
    return {
      success: false,
      error: {
        code: 'code' in error && typeof error.code === 'string' ? error.code : 'no_code_available',
        message: error.message,
        stack: error.stack,
        name: error.name,
        details: '',
      },
    } as const;
  }

  if (typeof error === 'string') {
    return {
      success: false,
      error: {
        code: error,
        message: error,
        stack: '',
        name: '',
        details: '',
      },
    } as const;
  }

  return {
    success: false,
    error: {
      code: 'no_code_available',
      message: 'unexpected error check the details attribute',
      stack: '',
      name: '',
      details: error,
    },
  } as const;
};

export const mapAuthSuccessOperation = <Data>(data: Data) => {
  return {
    success: true,
    data,
  } as const;
};

export const safeParseAuthOperation = async <T>(operation: () => Promise<T>) => {
  try {
    return mapAuthSuccessOperation(await operation());
  } catch (e) {
    return mapAuthErrorOperation(e);
  }
};

export const parseAuthResult = <T>(safeParseResult: AuthOperation<T>) => {
  if (safeParseResult.success) {
    return safeParseResult.data;
  }

  throw safeParseResult.error;
};

export function buildTokenInfo(token?: JWT) {
  if (!token) {
    return;
  }

  return {
    jwt: token.toString(),
    expiration: token.payload.exp,
    issuedAt: token.payload.iat,
    payload: token.payload,
    sub: token.payload.sub,
  };
}

export const buildUserPayload = ({
  user,
  tokens,
}: {
  user: GetCurrentUserOutput;
  tokens: AuthSession['tokens'];
}): AuthenticatedUserData => {
  const idToken = tokens?.idToken;

  return {
    email: idToken?.payload.email as string,
    sub: idToken?.payload.sub,
    username: user.username,
    groups: (idToken?.payload['cognito:groups'] as string[]) || [],
    customOmgYesProperties: idToken?.payload.omgyes
      ? JSON.parse(idToken?.payload.omgyes as string)
      : undefined,
  };
};
