export enum AnalyticsEvent {
  VIDEO_CLICK = 'video_clicked',
  VIDEO_TRACK = 'video_tracked',
  VIDEO_UNMUTE = 'video_unmuted',
  VIDEO_ERROR = 'video_errored',
  TECHNIQUE_EXPANDED = 'technique_expansion_clicked',
  FAQ_EXPANDED = 'faqs_expanded',
  CTA_HOMEPAGE = 'homepage_cta_clicked',
  CTA_PRICING = 'pricing_cta_clicked',
  LINK_CLICK = 'link_clicked',
  BUTTON_CLICK = 'button_clicked',
  CANCEL_TRRIAL = 'cancel_trial',
  ENTER_EXPERIMENT = 'experiment_entered',
  VIEW_ITEM_LIST = 'view_item_list',
  SCROLL_DEPTH = 'scroll_depth',
  AUTH_ERROR = 'auth_error',
  COPY_TEXT = 'text_copied',
  FIRST_NMH_ACCESS = 'first_nmh_access',
}

export enum Storage {
  APP = 'app_state',
}

export enum Cookie {
  COUNTRY = 'NEXT_COUNTRY',
  HOLIDAY = 'holiday_modal',
  VISITED = 'visited',
  EXPERIMENT = '_gaexp_omgyes',
  VARIANT = '_gaexp_variant',
  DISCOUNT = 'discountCode',
  UTMSOURCE = 'utm_src',
}

export enum NavMode {
  PRICING = 'pricing',
  DEFAULT = 'default',
  SIMPLE = 'simple',
  SIMPLE_CATHY = 'simple_cathy',
  NONE = 'none',
}

export enum FooterMode {
  DEFAULT = 'default',
  SIMPLE = 'simple',
  SIMPLE_CATHY = 'simple_cathy',
}

export enum Environment {
  PROD = 'prod',
  PROD_GO = 'prod-go',
  UAT = 'uat',
  DEV = 'dev',
  LOCAL = 'local',
  ALL = 'all',
}

export enum Theme {
  V1 = 'omgyes-v1',
  V2 = 'omgyes-v2',
  NMH = 'omgyes-nmh',
}

export enum MediaType {
  VIDEO = 'video',
  TEXT = 'text',
  IMAGE = 'image',
}

export enum TestimonialCategory {
  COUPLES = 'Couples',
  WOMEN = 'Women',
  MEN = 'Men',
}

export enum TechniqueSeason {
  SEASON_1 = 'season-1',
  SEASON_2 = 'season-2',
  SEASON_3 = 'season-3',
}

export enum TechniqueCategory {
  PHYSICAL_TOUCH = 'Physical Touch',
  MENTAL = 'Mental',
  EXPLORATORY = 'Exploratory',
}

export enum TextTag {
  P = 'p',
  SPAN = 'span',
}

export enum TextHeader {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  DIV = 'div',
}

export enum Route {
  HOME_V1 = '/',
  HOME = '/join',
  PRICING = '/join/pricing#top',
}

export enum SectionID {
  LEARN_FROM = 'LearnFrom',
  PRICING = 'pricing-info',
  WHAT_IS_OMGYES = 'WhatIsOMGYES',
  THE_TECHNIQUES = 'TheTechniques',
  OUR_RESEARCH = 'OurResearch',
  OUR_MISSION = 'OurMission',
  PRESS_AND_REVIEWS = 'PressAndReviews',
}

export enum LanguageCode {
  EN = 'en',
  DE = 'de',
  FR = 'fr',
  ES = 'es',
  ES_419 = 'es_419',
  IT = 'it',
  NL = 'nl',
  PT = 'pt',
  RU = 'ru',
}

export const LanguageLabels: { [key in LanguageCode]: string } = {
  [LanguageCode.EN]: 'English',
  [LanguageCode.DE]: 'Deutsch',
  [LanguageCode.FR]: 'Français',
  [LanguageCode.ES]: 'Español',
  [LanguageCode.ES_419]: 'Español (LA)',
  [LanguageCode.IT]: 'Italiano',
  [LanguageCode.NL]: 'Nederlands',
  [LanguageCode.PT]: 'Português',
  [LanguageCode.RU]: 'Русский',
};

export const UtilityLinks = [
  { url: '/gifts#/give/', label: 'label.gifts' },
  { url: '/about#/movement', label: 'footer.about' },
  { url: '/faq', label: 'footer.faq' },
  { url: '/doctors', label: 'footer.clinicians' },
  { url: '/affiliates', label: 'footer.affiliates' },
];
export const LegalLinks = [
  { url: '/legal#privacy', label: 'footer.privacy' },
  { url: '/legal#terms', label: 'footer.terms' },
];

export const PageID: Record<string, string> = {};

const constants = {
  Theme,
  MediaType,
};

export default constants;
