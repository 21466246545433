import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { CollectionData } from '@types.ts';
import clsx from 'clsx';
import _ from 'lodash';

import { TechniqueIcon } from '@components/TechniqueIcon.tsx';

import { navigateToLegacyTechnique } from '@lib/links.ts';
import { hexToRgbA } from '@pages/utils.ts';

export const Collection = ({
  collections,
  selectedCollection,
}: {
  collections: CollectionData[];
  selectedCollection: string;
}) => {
  const navigate = useNavigate();
  const selectedCollectionNumber = collections?.findIndex(
    x => _.kebabCase(x.fields.collection) === selectedCollection,
  );
  const collection = collections
    .slice(selectedCollectionNumber, selectedCollectionNumber + 1)
    .pop();
  const previous = (selectedCollectionNumber - 1 + collections.length) % collections.length;
  const next = (selectedCollectionNumber + 1) % collections.length;
  const [ hover, setHover ] = useState<string | undefined>(undefined);
  const showCollection = (v: number) => {
    collections?.length &&
      navigate(`/collection/${_.kebabCase(collections[v]?.fields.collection)}`);
  };

  if (!collection) return <></>;

  const { fields: collectionFields } = collection;
  const { techniqueGroups } = collectionFields;

  if (!techniqueGroups || !techniqueGroups[0].fields) {
    location.reload();
  }

  return (
    <>
      <div className={'flex flex-col gap-32px md:gap-40px lg:gap-48px w-full'}>
        {techniqueGroups.map(({ fields: techniqueGroup }, detailIndex) => {
          if (!techniqueGroup?.title) return <></>;
          return (
            <div
              key={`detail-${detailIndex}`}
              className={'flex flex-col gap-16px hover:cursor-pointer'}
            >
              <div>
                <div className={'self-stretch flex-start inline-flex justify-start item-start'}>
                  <p className={'collection-headline'}>{techniqueGroup.title}</p>
                </div>
              </div>
              <div className={'flex flex-col gap-16px lg:gap-0 mx-[-8px] lg:mx-[-16px]'}>
                {techniqueGroup.techniques.map(({ fields: technique }, index) => {
                  const bgColors = collectionFields.bgColor.split(';');
                  return (
                    <div
                      key={`entry-${detailIndex}-${technique.title}-${index}`}
                      className={clsx(
                        'px-8px py-8px lg:px-16px lg:py-16px justify-start items-start',
                        hover === `${detailIndex}.${index}` ? 'rounded-xl bg-[#FAFAFA]' : '',
                      )}
                      onClick={() => navigateToLegacyTechnique(technique.title)}
                      onMouseEnter={() => setHover(`${detailIndex}.${index}`)}
                      onMouseLeave={() => setHover(undefined)}
                    >
                      <div className="self-stretch pr-2 justify-start items-center gap-4 flex">
                        <div
                          style={{
                            background:
                              hover === `${detailIndex}.${index}`
                                ? collectionFields.techniqueIconHoverColor
                                : `linear-gradient(45deg, ${hexToRgbA(bgColors[0], 1)},  ${hexToRgbA(bgColors[1], 1)})`,
                            borderColor: collectionFields.bgColor.split(';')[0],
                          }}
                          className={
                            'w-[48px] h-[48px] lg:w-[56px] lg:h-[56px] rounded-lg border-[1px] justify-center items-center gap-2 flex'
                          }
                        >
                          <TechniqueIcon
                            technique={technique}
                            hovered={hover === `${detailIndex}.${index}`}
                          />
                        </div>
                        <div className="grow shrink basis-0 flex-col justify-center items-start inline-flex h-[48px]">
                          <div className="self-stretch collection-detail-title">
                            {technique.title}
                          </div>
                          <div className="self-stretch collection-detail-description">
                            {technique.description}
                          </div>
                        </div>
                        <div
                          className={clsx(
                            'justify-self-end hidden lg:block',
                            hover === `${detailIndex}.${index}`
                              ? 'text-ui-pepper'
                              : 'text-[#AAA9A9]',
                          )}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M6.41109 3.40458C6.1963 3.61069 6.1963 3.94486 6.41109 4.15097L10.4222 8L6.41109 11.849C6.1963 12.0551 6.1963 12.3893 6.41109 12.5954C6.62588 12.8015 6.97412 12.8015 7.18891 12.5954L11.5889 8.3732C11.8037 8.16709 11.8037 7.83292 11.5889 7.6268L7.18891 3.40458C6.97412 3.19847 6.62588 3.19847 6.41109 3.40458Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
        <div className={''}>
          <div className={'flex flex-row justify-between'}>
            <div
              className={'flex flex-row gap-8px sm:gap-12px items-center hover:cursor-pointer'}
              onClick={() => showCollection(previous)}
              onMouseEnter={() => setHover('navigator:left')}
              onMouseLeave={() => setHover(undefined)}
            >
              <div
                className={
                  hover === 'navigator:left'
                    ? 'collection-arrow-wrapper-hover'
                    : 'collection-arrow-wrapper'
                }
              >
                <div className={'w-full h-full flex justify-center items-center'}>
                  <svg
                    width="14"
                    height="12"
                    viewBox="0 0 14 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.56641 0.749999C4.48438 3.37956 3.10625 4.93174 0.875001 5.13261L0.875 6.86739C3.10625 7.06826 4.48438 8.62043 4.56641 11.25L6.45313 10.7935C6.28906 8.98565 5.05859 7.30565 3.74609 6.86739L14 6.86739L14 5.13261L3.74609 5.13261C5.05859 4.69435 6.28906 3.01435 6.45313 1.20652L4.56641 0.749999Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
              </div>
              <div
                className={
                  hover === 'navigator:left'
                    ? 'collection-navigation-text-hover'
                    : 'collection-navigation-text'
                }
                onClick={() => showCollection(previous)}
              >
                {collections.slice(previous, previous + 1).pop()?.fields.collection}
              </div>
            </div>

            <div
              className={'flex flex-row gap-8px sm:gap-12px items-center hover:cursor-pointer'}
              onClick={() => showCollection(next)}
              onMouseEnter={() => setHover('navigator:right')}
              onMouseLeave={() => setHover(undefined)}
            >
              <div
                className={
                  hover === 'navigator:right'
                    ? 'collection-navigation-text-hover'
                    : 'collection-navigation-text'
                }
              >
                {collections.slice(next, next + 1).pop()?.fields.collection}
              </div>
              <div
                className={
                  hover === 'navigator:right'
                    ? 'collection-arrow-wrapper-hover'
                    : 'collection-arrow-wrapper'
                }
              >
                <div className={'w-full h-full flex justify-center items-center'}>
                  <svg
                    width="14"
                    height="12"
                    viewBox="0 0 14 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.43359 11.25C9.51562 8.62043 10.8938 7.06826 13.125 6.86739V5.13261C10.8938 4.93174 9.51562 3.37957 9.43359 0.75L7.54687 1.20652C7.71094 3.01435 8.94141 4.69435 10.2539 5.13261H0V6.86739H10.2539C8.94141 7.30565 7.71094 8.98565 7.54687 10.7935L9.43359 11.25Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
