import { AuthenticationErrors } from './amplify/identity-provider.enum';

export const authenticationErrorMessageMap: { [key in AuthenticationErrors]: string } = {
  [AuthenticationErrors.FAILED_TO_SIGN_IN]: 'Failed to sign in',
  [AuthenticationErrors.FAILED_TO_VALIDATE_PASSWORD]: 'Failed to validate password',
  [AuthenticationErrors.INVALID_CREDENTIALS]: 'login.loginError',
  [AuthenticationErrors.INVALID_PASSWORD]: 'Invalid password',
  [AuthenticationErrors.PASSWORD_RESET_REQUIRED]:
    'Account locked. Please reset your password to continue',
  // TODO: we should provide better feedback. We include this in the translation files
  // [AuthenticationErrors.LOCKED_ACCOUNT]: 'Account locked. Please contact support',
  [AuthenticationErrors.LOCKED_ACCOUNT]: 'login.loginError',
  [AuthenticationErrors.TOO_MANY_LOGIN_ATTEMPTS]:
    'You have exceeded the maximum number of login attempts. Please try again later',
  [AuthenticationErrors.PASSWORD_CONFIRMATION_DOES_NOT_MATCH]: 'The passwords do not match',
  [AuthenticationErrors.PASSWORD_POLICY_NOT_MET]: 'Password does not meet policy requirements',
  [AuthenticationErrors.EMAIL_ALREADY_TAKEN]: 'Email already taken',
  [AuthenticationErrors.SESSION_EXPIRED]: 'Session expired. Please sign in again',
  [AuthenticationErrors.PASSWORD_REGISTER_ERROR]: 'Unable to register. Please try again later',
  // TODO: We sould include this in the translation files
  [AuthenticationErrors.GENERIC]: 'Unable to sign in. Please try again later.',
  // TODO: We sould include this in the translation files
  [AuthenticationErrors.LIMIT_EXCEEDED_EXCEPTION]: 'Too many attempts. Please try again later.',
  [AuthenticationErrors.INVALID_CODE]: 'password.reset.tokenExpired',
  [AuthenticationErrors.CODE_EXPIRED]: 'password.reset.tokenExpired',
  [AuthenticationErrors.INVALID_PARAMETER]: 'password.reset.invalidPassword',
  [AuthenticationErrors.SIGN_OUT_TIMEOUT]: '',
};
