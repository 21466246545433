import { selectImageFromContentfulImages } from '@lib/helpers';
import type { Image } from '@pages/gift/GiftPage.types';

interface GiftHeaderProps {
  header: string;
  subheader: string;
  headerBackgroundImages: Image[];
}

export const GiftPageHeader = ({ header, subheader, headerBackgroundImages }: GiftHeaderProps) => {
  return (
    <div className="gift-header">
      <div className={'gift-header-image-container'}>
        <div className={'gift-header-image-to-sm'}>
          <img
            src={`${selectImageFromContentfulImages(headerBackgroundImages, 360)?.fields.file.url}?w=768`}
            className={'object-cover w-full'}
          />
        </div>
        <div className={'gift-header-image-from-sm-to-lg overflow-hidden '}>
          <img
            src={`${selectImageFromContentfulImages(headerBackgroundImages, 640)?.fields.file.url}?w=1280`}
            className={'object-cover min-h-full w-full'}
          />
        </div>
        <div className={'gift-header-image-from-lg overflow-hidden '}>
          <img
            src={`${selectImageFromContentfulImages(headerBackgroundImages, 1280)?.fields.file.url}?w=1280`}
            className={'object-cover min-h-full w-full'}
          />
        </div>
      </div>

      <div className="gift-header-text-container">
        <div className="gift-headline">{header}</div>
        <div className="gift-sub-headline">{subheader}</div>
      </div>
    </div>
  );
};
