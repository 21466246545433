import React from 'react';

interface ButtonCtaProps {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

const ButtonCta: React.FC<ButtonCtaProps> = ({ className, children, onClick }) => {
  return (
    <button
      className={`
        flex justify-center items-center gap-2 
        w-full flex-1 
        px-10 py-3
        text-white text-xl font-mabry-bold
        bg-[#2973DB] hover:bg-[#1e60bc]
        border border-[#2973DB] 
        rounded-[53px]
        shadow-[0_2px_0_0_rgba(41,38,37,0.05)]
        ${className || ''}
      `}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default ButtonCta;
