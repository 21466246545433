import React from 'react';

export const FormContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="min-h-screen p-16 sm:px-30 bg-auth-bg bg-auth-gradient flex justify-center items-center">
      <div className="flex h-full rounded-xl p-10 bg-white min-w-[400px] mx-auto my-auto">
        {children}
      </div>
    </div>
  );
};
