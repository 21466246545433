import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useContentfulLiveUpdates } from '@contentful/live-preview/react';
import clsx from 'clsx';

import { useContentful } from '@lib/contexts/ContentfulContext';
import useAnalytics from '@lib/hooks/useAnalytics.ts';
import { AnalyticsEvent } from '@root/constants.ts';

import Beaker from './atoms/svg/research/beaker.svg';

import { NMH_LOOKUP } from '@/config';
import type { NewMemberHomeData } from '@/types.ts';

export type Research = {
  title: string;
  description: string;
};
export const NerdOutOnResearch = () => {
  const { analytics } = useAnalytics();
  const params = useParams();
  const [ publishedData, setPublishedData ] = useState<NewMemberHomeData>();
  const { getEntry } = useContentful();

  useEffect(() => {
    getEntry(NMH_LOOKUP['en'], {
      include: 3,
    }).then((data: NewMemberHomeData) => {
      setPublishedData(data);
    });
  });

  const data = useContentfulLiveUpdates(publishedData);
  const content = data?.fields;

  if (!content) return <></>;

  const openResearchLink = (link: string | URL | undefined) => {
    analytics.track({
      event: AnalyticsEvent.LINK_CLICK,
      props: { ...params, name: link?.toString(), link_type: 'nmh_research' },
    });
    window.open(link);
  };

  return (
    <>
      <div className={'nmh-research-section'}>
        <div className={'nmh-x-padding'}>
          <div className={'nmh-max768-container'}>
            <div className={'nmh-research-container'}>
              <div>
                <p className={'research-headline'}>{content.researchHeading}</p>
              </div>
              <div className={'self-stretch rounded-lg flex-col justify-start items-center flex'}>
                {content.research.map((item, index) => {
                  return (
                    <div
                      key={`Research-${index}`}
                      onClick={() => openResearchLink(item.fields.link)}
                      className={clsx(
                        'flex self-stretch py-6 border-b border-gray-200 justify-start items-start gap-[24px] inline-flex hover:text-ui-pepper text-ui-tbd',
                        'hover:cursor-pointer',
                      )}
                    >
                      <div
                        className={clsx('grow shrink basis-0 justify-start items-start flex gap-4')}
                      >
                        <div className="w-10 h-10 bg-[#E7EAF9] rounded-lg border-b border-indigo-200 justify-center items-center gap-2 flex">
                          <div className="w-[24px] h-[24px] lg:w-[28px] lg:h-[28px] flex justify-center">
                            {item.fields.icon ? (
                              <img
                                src={item.fields.icon.fields.file?.url}
                                alt={'Research'}
                                style={{ height: '100%' }}
                              />
                            ) : (
                              <img
                                src={Beaker}
                                alt={'Research'}
                                style={{ width: '100%', height: '100%' }}
                              />
                            )}
                          </div>
                        </div>
                        <div
                          className={clsx(
                            'flex-shrink grow basis-0 flex-col justify-start items-start gap-1 inline-flex text-ui-coal hover:text-ui-pepper',
                          )}
                        >
                          <div className="self-stretch research-title leading-tight">
                            {item.fields.heading}
                          </div>
                          <div className="self-stretch research-description leading-tight">
                            {item.fields.publication}
                          </div>
                        </div>
                      </div>
                      <div className={'flex self-center'}>
                        <svg
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8.01386 4.53844C7.74538 4.79608 7.74538 5.21379 8.01386 5.47143L13.0277 10.2827L8.01386 15.094C7.74538 15.3516 7.74538 15.7693 8.01386 16.027C8.28235 16.2846 8.71765 16.2846 8.98614 16.027L14.4861 10.7492C14.7546 10.4916 14.7546 10.0739 14.4861 9.81622L8.98614 4.53844C8.71765 4.28081 8.28235 4.28081 8.01386 4.53844Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
