import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { SimpleDialogContainer } from 'react-simple-dialogs';
import { ContentfulLivePreviewProvider } from '@contentful/live-preview/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { ContentfulProvider } from '@lib/contexts/ContentfulContext';

import { initializeSentry } from './utils/sentry';
import { AppRoutes } from './routes';

import { Fallback } from '@/components/fallback';

// Initialize Sentry
initializeSentry();

const queryClient = new QueryClient();

function App() {
  return (
    <React.Suspense fallback={<Fallback />}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools />
        <ContentfulLivePreviewProvider
          locale="en-US"
          enableInspectorMode
          enableLiveUpdates
          debugMode
        >
          <ContentfulProvider>
            <BrowserRouter
              future={
                {
                  v7_relativeSplatPath: false,
                  v7_startTransition: false ,   
                }
              }

              basename={'/members'}>
              <SimpleDialogContainer />
              <AppRoutes />
            </BrowserRouter>
          </ContentfulProvider>
        </ContentfulLivePreviewProvider>
      </QueryClientProvider>
    </React.Suspense>
  );
}

export default App;
