import type { ReactNode } from 'react';
import { useState } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import type { Block, Inline } from '@contentful/rich-text-types';
import { BLOCKS } from '@contentful/rich-text-types';

import type { GiftPageData } from '../GiftPage.types';

interface GiftPageFaqProps {
  data: GiftPageData;
}

export const GiftPageFaq = ({ data }: GiftPageFaqProps) => {
  const [ selectedFAQ, setSelectedFAQ ] = useState<string>();

  return (
    <div className="gift-page-faq">
      <div className="gift-x-padding">
        <div className="gift-max1040-container">
          <div className="gift-page-faq-title">{data.faqHeading}</div>
          {data.faqItems && (
            <div className="gift-page-faq-list">
              {data.faqItems?.map(({ fields }, index) => (
                <div className="gift-page-faq-item" key={`${fields.title}-${index}`}>
                  <div
                    className="gift-page-faq-item-question-container"
                    onClick={() =>
                      selectedFAQ === fields.title
                        ? setSelectedFAQ(undefined)
                        : setSelectedFAQ(fields.title)
                    }
                  >
                    <div className={selectedFAQ === fields.title ? 'text-light-rust' : ''}>
                      {fields.question}
                    </div>
                    <div className="gift-page-faq-item-question-accordion-icon">
                      {selectedFAQ === fields.title ? (
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.25573 13.2361C4.51337 13.5046 4.93108 13.5046 5.18872 13.2361L10 8.22227L14.8113 13.2361C15.0689 13.5046 15.4866 13.5046 15.7443 13.2361C16.0019 12.9677 16.0019 12.5323 15.7443 12.2639L10.4665 6.76386C10.2089 6.49538 9.79114 6.49538 9.53351 6.76386L4.25573 12.2639C3.99809 12.5323 3.99809 12.9677 4.25573 13.2361Z"
                            fill="#292625"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.25573 6.76386C4.51337 6.49538 4.93108 6.49538 5.18872 6.76386L10 11.7777L14.8113 6.76386C15.0689 6.49538 15.4866 6.49538 15.7443 6.76386C16.0019 7.03235 16.0019 7.46765 15.7443 7.73614L10.4665 13.2361C10.2089 13.5046 9.79114 13.5046 9.53351 13.2361L4.25573 7.73614C3.99809 7.46765 3.99809 7.03235 4.25573 6.76386Z"
                            fill="currentColor"
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                  {selectedFAQ === fields.title ? (
                    <div className="gift-page-faq-item-answer-container">
                      {documentToReactComponents(fields.answer, {
                        renderNode: {
                          [BLOCKS.PARAGRAPH]: (_node: Block | Inline, children: ReactNode) => (
                            <div>{children}</div>
                          ),
                        },
                      })}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
