import type { Dispatch } from 'react';
import { useContext } from 'react';

import type { Action } from './createDataContext';
import { createDataContext } from './createDataContext';

const ACTIONS = {
  VIDEO_UPDATE: 'videoUpdate',
};

type VideoState = {
  videoId?: string | null;
};

type VideoActions = {
  setVideo: (d: Dispatch<Action>) => (vId: string | null) => void;
};

const videoReducer = (state: VideoState, action: Action): VideoState => {
  switch (action.type) {
    case ACTIONS.VIDEO_UPDATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const setVideo = (dispatch: Dispatch<Action>) => async (videoId: string | null) =>
  dispatch({ type: ACTIONS.VIDEO_UPDATE, payload: { videoId }});

export const { Provider: VideoProvider, Context: VideoContext } = createDataContext<
  VideoActions,
  VideoState
>(videoReducer, { setVideo }, { videoId: null });

export const useVideoContext = () => useContext(VideoContext);
