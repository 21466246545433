import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import PageLayout from '@layouts/PageLayout/PageLayout';

import PageFooter from '@components/common/PageFooter';
import PageHeader from '@components/common/PageHeader';

import { useAuthenticatedSession } from '@lib/authentication';
import AdminPortal from '@pages/admin/AdminPortal';
import ProductCatalog from '@pages/admin/ProductCatalog/ProductCatalog';
import ArticlePage from '@pages/ArticlePage';
import AuthPage from '@pages/Auth/AuthPage';
import WhoAmIPage from '@pages/Auth/WhoAmI';
import Checkout from '@pages/checkout/checkout';
// Page imports
import CollectionPage from '@pages/CollectionPage';
import DateNightPage from '@pages/DateNightPage';
import GiftPage from '@pages/gift/GiftPage';
import NewMemberHome from '@pages/NewMemberHome';
import Redeem from '@pages/redeem/redeem';
import Transactions from '@pages/Transactions/transactions';

export const AUTH_ROUTES = [
  '/login',
  '/signup',
  '/forgot-password',
  '/set-password',
  '/reset-password',
];

export const LOGOUT_ROUTES = [ '/logout' ];

const Index = () => {
  const location = useLocation();
  const { accessToken } = useAuthenticatedSession();
  const queryParams = location.search;

  if (location.pathname === '/') {
    return <Navigate to={accessToken ? `/home${queryParams}` : `/login${queryParams}`} />;
  }

  return (
    <>
      <Outlet />
    </>
  );
};

export const AppRoutes = () => (
  <Routes>
    <Route index element={<Index />} />

    {/* Routes with no header/footer */}
    <Route path="" element={<PageLayout hideHeader={true} hideFooter={true} />}>
      <Route path="/collection/:id" element={<CollectionPage />} />
      <Route path="/date-night/:id" element={<DateNightPage />} />
      <Route path="/article/:id" element={<ArticlePage />} />
      <Route path="home/:locale?" element={<NewMemberHome />} />
    </Route>

    {/* Auth routes with header and footer */}
    {AUTH_ROUTES.map(path => (
      <Route
        key={path}
        path={path}
        element={
          <>
            <PageHeader />
            <AuthPage />
            <PageFooter />
          </>
        }
      />
    ))}

    {/* Logout routes */}
    {LOGOUT_ROUTES.map(path => (
      <Route key={path} path={path} element={<AuthPage />} />
    ))}

    {/* Gift and redeem routes */}
    <Route path="">
      <Route path="gift" element={<GiftPage />} />
      <Route path="redeem" element={<Redeem />} />
      <Route path="checkout" element={<Checkout />} />
    </Route>

    {/* Routes with default layout */}
    <Route path="" element={<PageLayout />}>
      <Route path="change-password" element={<AuthPage />} />
      <Route path="/whoami" element={<WhoAmIPage />} />
    </Route>

    {/* Admin routes */}
    <Route path="admin" element={<AdminPortal />}>
      <Route path="" element={<PageLayout hideFooter />}>
        <Route path="transactions" element={<Transactions />} />
        <Route path="product-catalog" element={<ProductCatalog />} />
      </Route>
    </Route>

    {/* Fallback route */}
    <Route path="*" element={<Navigate to="/home" replace={true} />} />
  </Routes>
);
