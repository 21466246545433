import React from 'react';

interface ButtonCtaProps {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
}

const ButtonCta: React.FC<ButtonCtaProps> = ({ children, className = '', ...args }) => {
  return (
    <button
      className={`
        flex justify-center items-center gap-2 
        py-[7px] px-6 
        flex-1 
        text-white text-sm font-[MabryPro-Medium]
        rounded-[53px] 
        border border-[#2973db]
        bg-[#2973db] 
        shadow-[0_2px_0_0_rgba(41,38,37,0.05)]
        hover:bg-[rgb(30,96,188)]
        disabled:bg-[rgb(225,225,225)]
        ${className}
      `}
      {...args}
    >
      {children}
    </button>
  );
};

export default ButtonCta;
