import React from 'react';

import ButtonCta from '../ButtonCta';

export const FormSubmit: React.FC<any> = ({ className, ...args }) => (
  <ButtonCta className={className}>
    <input {...args} type="submit" />
  </ButtonCta>
);

export const setSubmitError = (setErrors: any, err: any) =>
  setErrors((errors: any) => ({ ...errors, submit: err }));
