import { useEffect, useMemo } from 'react';
import type { ColDef } from 'ag-grid-community'; // Theme
import { AgGridReact } from 'ag-grid-react'; // React Grid Logic
import _ from 'lodash';
import { useRecoilState, useRecoilValue } from 'recoil';

import type { Entitlement } from '@pages/admin/ProductCatalog/store.ts';
import {
  editEntitlementsState,
  entitlementsState,
  productDetails,
  resetEdits,
  selectedProductState,
} from '@pages/admin/ProductCatalog/store.ts';

import 'ag-grid-community/styles/ag-grid.css'; // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css';

export default function EntitlementsList() {
  const reset = useRecoilValue(resetEdits);
  const [ entitlements ] = useRecoilState(entitlementsState);

  const productId = useRecoilValue(selectedProductState);
  const product = useRecoilValue(productDetails(productId));
  const [ selectedEntitlements, setSelectedEntitlements ] = useRecoilState(editEntitlementsState);

  useEffect(() => {
    setSelectedEntitlements(_.map(product?.entitlements, 'name'));
  }, [ product, reset ]);

  const columnDefs: ColDef<Entitlement>[] = useMemo(
    () => [
      {
        headerName: '',
        field: 'id',
        valueGetter: ({ node }) => selectedEntitlements.includes(node?.data?.name || ''),
        valueSetter: ({ newValue, node }) => {
          setSelectedEntitlements(entitlements =>
            newValue
              ? [ ...entitlements, node?.data?.name! ]
              : entitlements.filter(name => name !== node?.data?.name!),
          );
          return true;
        },
        editable: !!productId,
        cellRenderer: 'agCheckboxCellRenderer',
        cellEditor: 'agCheckboxCellEditor',
      },
      {
        headerName: 'Entitlement Name',
        field: 'name',
        filter: 'agTextColumnFilter',
      },
    ],
    [ productId, selectedEntitlements ],
  );

  return (
    <div className="ag-theme-quartz w-full h-full">
      <AgGridReact
        // @ts-ignore
        columnDefs={columnDefs}
        rowData={entitlements}
        autoSizeStrategy={{ type: 'fitCellContents' }}
      />
    </div>
  );
}
