import type { ReactNode } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import type { Block, Inline } from '@contentful/rich-text-types';
import { BLOCKS } from '@contentful/rich-text-types';
import type { TypeArticleCarouselQuotes } from '@types.ts';

import { ArticleCarousel } from '@components/ArticleCarousel.tsx';

export const ArticleCarouselQuotes = ({ fields }: { fields: TypeArticleCarouselQuotes }) => {
  return (
    <ArticleCarousel
      showing={{
        xs: 1,
        sm: 1.8,
        md: 2,
        lg: 2,
      }}
      maxWidthClasses={'max-w-[312px]'}
    >
      {fields.quotes.map((entry, index: number) => {
        return (
          <div className={'article-quote'} key={index}>
            <div>
              <svg
                width="30"
                height="24"
                viewBox="0 0 30 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 23.2739V9.17715C0 2.8177 3.97213 0.273926 9.82579 0.273926H13.1707V5.78545H9.5122C7.00348 5.78545 5.43554 6.84536 5.43554 8.96517C5.43554 10.979 6.6899 12.1449 9.09408 12.1449H10.9756V23.2739H0ZM16.7247 23.2739V9.17715C16.7247 2.8177 20.6969 0.273926 26.5505 0.273926H30V5.78545H26.3415C23.6237 5.78545 22.2648 6.84536 22.2648 8.96517C22.2648 10.979 23.6237 12.1449 25.8188 12.1449H27.7003V23.2739H16.7247Z"
                  fill="#D8591C"
                />
              </svg>
            </div>
            <div>
              {documentToReactComponents(entry.fields.quote, {
                renderNode: {
                  [BLOCKS.PARAGRAPH]: (_node: Block | Inline, children: ReactNode) => (
                    <span>{children}</span>
                  ),
                },
              })}
            </div>
          </div>
        );
      })}
    </ArticleCarousel>
  );
};
