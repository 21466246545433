import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import i18next from 'i18next';

export const useLocale = () => {
  const [ cookies, setCookie ] = useCookies([ 'loc' ]);
  const [ locale, setLocale ] = useState('');

  useEffect(() => {
    const queryLng = new URLSearchParams(window.location.search).get('lng');
    const storedLng = localStorage.getItem('i18nextLng');
    const cookieLng = cookies.loc;

    let finalLocale = queryLng || cookieLng || storedLng || 'en';

    if (finalLocale === 'en-US') finalLocale = 'en';

    if (finalLocale !== cookieLng)
      setCookie('loc', finalLocale, {
        path: '/',
        domain: '.omgyes.com',
        expires: new Date('9999-12-31T23:59:59.000Z'),
      });
    if (finalLocale !== storedLng) localStorage.setItem('i18nextLng', finalLocale);

    if (locale !== finalLocale) {
      setLocale(finalLocale);
      i18next.changeLanguage(finalLocale);
    }
  }, [ cookies, setCookie, locale ]);

  return locale;
};
