import { useQueryAuthenticatedUser } from '../../queries/use-query-authenticated-user';

import type { UseAuthenticatedUserReturnType } from './use-authenticated-user.types';

export const useAuthenticatedUser = (): UseAuthenticatedUserReturnType => {
  const { data, isLoading } = useQueryAuthenticatedUser({
    select: ({ authenticateUser }) => authenticateUser,
  });

  if (!isLoading) {
    if (data) {
      return {
        isAuthenticated: true,
        authenticatedUser: data,
        isLoading,
      };
    }

    return {
      isAuthenticated: false,
      authenticatedUser: undefined,
      isLoading,
    };
  }

  return {
    isLoading,
  };
};
