import { GenIcon } from 'react-icons';

export const ExclamationCircle = GenIcon({
  tag: 'svg',
  attr: { viewBox: '0 0 16 16', fill: 'currentColor', 'aria-hidden': 'true' },
  child: [
    {
      tag: 'path',
      attr: {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z',
      },
      child: [],
    },
    {
      tag: 'path',
      attr: {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M9 11C9 11.5523 8.55228 12 8 12C7.44772 12 7 11.5523 7 11C7 10.4477 7.44772 10 8 10C8.55228 10 9 10.4477 9 11Z',
      },
      child: [],
    },
    {
      tag: 'path',
      attr: {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        d: 'M8 9C8.41421 9 8.75 8.66421 8.75 8.25V4.75C8.75 4.33579 8.41421 4 8 4C7.58579 4 7.25 4.33579 7.25 4.75V8.25C7.25 8.66421 7.58579 9 8 9Z',
      },
      child: [],
    },
  ],
});
