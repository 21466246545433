import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import ProgressBar from '@atoms/ProgressBar.tsx';

import { useLocale } from '@lib/contexts/LocaleContext';

const LoadingScreen = ({ showText }: { showText?: boolean }) => {
  const { t } = useTranslation();
  const [ cookies ] = useCookies([ 'showWelcomeText' ]);
  const shouldShowText = showText ?? !!cookies.showWelcomeText;
  const locale = useLocale();

  return (
    <div className="absolute h-screen w-screen bg-[#F7F7F7] z-50">
      <div className="flex flex-col justify-between h-full">
        {/* Top SVG */}
        <svg
          className="w-full h-auto"
          viewBox="0 0 1280 424"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
        >
          <path d="M1280 0H0V424H1280V0Z" fill="url(#paint0_radial_44_8)" />
          <path d="M1280 0H0V276H1280V0Z" fill="url(#paint1_radial_44_8)" />
          <defs>
            <radialGradient
              id="paint0_radial_44_8"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(1043.5 -90.6379) rotate(90.8048) scale(427.18 895.028)"
            >
              <stop stopColor="#F1CC9D" />
              <stop offset="1" stopColor="#F7F7F7" stopOpacity="0" />
            </radialGradient>
            <radialGradient
              id="paint1_radial_44_8"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(185.5 -49) rotate(88.9605) scale(248.041 599.797)"
            >
              <stop stopColor="#F1CC9D" />
              <stop offset="1" stopColor="#F7F7F7" stopOpacity="0" />
            </radialGradient>
          </defs>
        </svg>

        {/* Bottom SVG */}
        <svg
          className="w-full h-auto"
          viewBox="0 0 1280 361"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
        >
          <path
            d="M916.5 100C1064.9 20.4 1218.83 0.166667 1280 0V360.5H0V122.5C74.5 120.5 148.833 132.406 243 175.5C361 229.5 416 235 524.5 235C633 235 731 199.5 916.5 100Z"
            fill="url(#paint0_radial_1722_684)"
          />
          <path
            d="M916.5 100C1064.9 20.4 1218.83 0.166667 1280 0V360.5H0V122.5C74.5 120.5 148.833 132.406 243 175.5C361 229.5 416 235 524.5 235C633 235 731 199.5 916.5 100Z"
            fill="url(#paint1_radial_1722_684)"
          />
          <defs>
            <radialGradient
              id="paint0_radial_1722_684"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(611.5 226.5) rotate(92.1489) scale(266.688 677.188)"
            >
              <stop stopColor="#F2D3AC" />
              <stop offset="1" stopColor="#F7F7F7" stopOpacity="0" />
            </radialGradient>
            <radialGradient
              id="paint1_radial_1722_684"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(1126 62.5) rotate(87.4579) scale(236.733 487.622)"
            >
              <stop offset="0.100474" stopColor="#F2D1A7" />
              <stop offset="1" stopColor="#F7F7F7" stopOpacity="0" />
            </radialGradient>
          </defs>
        </svg>
      </div>
      <div
        className={`absolute inset-0 text-center text-gray-700 flex flex-col items-center ${shouldShowText ? 'pt-[146px] px-6 sm:pt-[202px] lg:pt-[248px]' : 'justify-center'}`}
      >
        {shouldShowText && (
          <>
            <h2 className="text-4xl">{t('header.home.splashModal.welcome')}</h2>
            <p className="text-lg mt-4 mb-60px">
              {locale === 'en' ? (
                <>
                  <span className="block">{t('checkoutPage.confirmation.variantCopy1')}</span>
                  <span className="block">{t('checkoutPage.confirmation.variantCopy2')}</span>
                </>
              ) : (
                <span className="block">{t('checkoutPage.confirmationModal.success')}</span>
              )}
            </p>
          </>
        )}
        <ProgressBar />
      </div>
    </div>
  );
};

export default LoadingScreen;
