import React, { useEffect, useMemo, useState } from 'react';
import type { FileConfig } from 'react-player/file';
import type FilePlayer from 'react-player/file';
import ReactPlayer from 'react-player/file';
import clsx from 'clsx';

import type { IVideoDisplay } from '@components/types';

import { noop } from '@lib/helpers';
import { LanguageCode } from '@root/constants';

export interface IVideoDisplayHtml5 extends IVideoDisplay<FilePlayer> {}

const VideoDisplayHtml5: React.FC<IVideoDisplayHtml5> = ({
  id,
  playerRef,
  url,
  style,
  className,
  subtitles = [],
  subtitlesEnabled = false,
  subtitleTrack = LanguageCode.EN,
  width = '100%',
  height = '100%',
  loop = false,
  muted = false,
  volume = 1,
  seeking = false,
  playing = false,
  controls = false,
  loading: PropLoading = false,
  onClick = noop,
  onReady = noop,
  onPlay = noop,
  onPause = noop,
  onDuration = noop,
  onBuffer = noop,
  onBufferEnd = noop,
  onProgress = noop,
  onEnded = noop,
  onMuteToggle = noop,
  onSubtitleChange = noop,
}) => {
  const [ mounted, setMounted ] = useState<boolean>(false);
  const [ initialized, setInitialized ] = useState<boolean>(true);
  const [ ready, setReady ] = useState<boolean>(false);
  const [ loading, setLoading ] = useState<boolean>(false);

  seeking;
  loading;

  const config = useMemo<FileConfig>(
    () => ({
      file: {
        hlsOptions: {
          startLevel: 8,
          xhrSetup: function (xhr: any) {
            xhr.withCredentials = url?.includes('omgyes.com') ? true : false; // send cookies
          },
        },
      },
      attributes: url?.includes('omgyes.com')
        ? {
          crossOrigin: 'use-credentials',
        }
        : {},
      tracks:
        subtitles &&
        Object.values(subtitles).map(({ src, srcLang, label, default: active }) => ({
          kind: 'subtitles',
          src: src || '',
          srcLang: srcLang || '',
          label: label || '',
          default: active || srcLang === LanguageCode.EN,
        })),
    }),
    [ subtitles ],
  );

  useEffect(() => setLoading(PropLoading), [ PropLoading ]);
  // useEffect(() => setSeeking(PropSeeking), [ PropSeeking ]);
  // useEffect(() => setVolume(PropVolume), [ PropVolume ]);

  useEffect(() => {
    if (!ready || !initialized) setLoading(true);
    setLoading(false);
  }, [ ready, initialized ]);

  useEffect(() => {
    setMounted(true);
    if (typeof window !== 'undefined') {
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (!playerRef) return;

    const handleVolumeChange = (evt: Event) => {
      onMuteToggle((evt.target as HTMLVideoElement).muted);
    };

    const video = playerRef.current?.getInternalPlayer() as HTMLVideoElement;
    if (!video) return;

    video.addEventListener('volumechange', handleVolumeChange);

    return () => {
      video.removeEventListener('volumechange', handleVolumeChange);
    };
  }, [ playerRef, onMuteToggle ]);

  /**
   * Manage Subtitles from video
   */
  useEffect(() => {
    if (!subtitleTrack || !playerRef) return;

    const video = playerRef.current?.getInternalPlayer() as HTMLVideoElement;
    if (!video) return;

    for (let i = 0; i < video.textTracks.length; i++) {
      const { language } = video.textTracks[i];
      video.textTracks[i].mode =
        language === subtitleTrack && subtitlesEnabled ? 'showing' : 'disabled';
    }

    const handleTrackChange = () => {
      onSubtitleChange(video.textTracks);
    };
    video.textTracks.addEventListener('change', handleTrackChange);
    return () => {
      video.textTracks.removeEventListener('change', handleTrackChange);
    };
  }, [ playerRef?.current, subtitlesEnabled, subtitleTrack ]);

  if (!mounted) {
    return null;
  }

  return (
    <div
      className={clsx(
        'content-full h-full w-full',
        'flex flex-col items-center justify-center',
        className,
      )}
      onClick={() => onClick()}
    >
      <ReactPlayer
        id={id}
        url={url}
        ref={playerRef}
        width={width}
        height={height}
        controls={controls}
        config={config}
        playsinline={true}
        playing={playing}
        volume={volume}
        muted={muted}
        loop={loop}
        stopOnUnmount={true}
        onReady={() => {
          setReady(true);
          onReady();
        }}
        onPlay={() => {
          setLoading(false);
          onPlay();
        }}
        onPause={() => {
          onPause();
        }}
        onBuffer={() => {
          setLoading(true);
          onBuffer();
        }}
        onBufferEnd={() => {
          setLoading(false);
          onBufferEnd();
        }}
        onError={err => {
          console.log('VideoDisplayHTML5 :: err -', err);
          // throw new Error(err);
        }}
        onDuration={onDuration}
        onProgress={onProgress}
        onEnded={onEnded}
        style={{ backgroundColor: 'black', ...style }}
      />

      {/*
      <div className={clsx(
        'absolute top-1/2 left-1/2',
        'transition-opacity duration-300',
        'opacity-0',
        loading && 'delay-300 opacity-100',
      )}><Loader /></div>
      */}
    </div>
  );
};

export default VideoDisplayHtml5;
