import type { SVGProps } from 'react';
import React from 'react';
import clsx from 'clsx';

export interface IIcon {
  icon: React.FC<SVGProps<SVGSVGElement>> | string;
  color?: 'black' | 'white' | 'rust' | 'coal';
  shape?: 'round' | 'square' | 'circle';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  iconFill?: 'black' | 'white' | 'rust';
  padded?: boolean;
  transparent?: boolean;
  invert?: boolean;
  className?: string;
  iconClassName?: string;
}

const Icon: React.FC<IIcon> = ({
  icon: DynamicIcon,
  color,
  className,
  iconClassName,
  iconFill,
  shape,
  size = 'md',
  padded = true,
  transparent = false,
  invert = false,
}) => (
  <div
    className={clsx(
      size === 'xs' && 'w-16px h-16px p-2px',
      size === 'sm' && 'w-32px h-32px p-4px',
      size === 'md' && 'w-40px h-40px p-12px',
      size === 'lg' && 'w-48px h-48px p-6px',
      size === 'xl' && 'w-64px h-64px p-10px',
      size === 'xxl' && 'w-80px h-80px',
      shape === 'circle' && !transparent && 'rounded-full',
      shape === 'round' && !transparent && 'rounded-md',
      (!padded || !shape) && '!p-0',
      !transparent && !invert && color === 'white' && 'bg-white',
      !transparent && !invert && color === 'black' && 'bg-black',
      !transparent && !invert && color === 'coal' && 'bg-ui-coal',
      !transparent && invert && color === 'white' && 'border border-white',
      !transparent && invert && color === 'black' && 'border border-black',
      !transparent && !invert && color === 'coal' && 'border border-ui-coal',
      className,
    )}
  >
    <DynamicIcon
      className={clsx(
        !iconFill && color === 'white' && '!fill-white',
        !iconFill && color === 'black' && '!fill-black',
        !iconFill && color === 'rust' && '!fill-ui-rust',
        !iconFill && color === 'coal' && '!fill-ui-coal',
        iconFill === 'white' && '!fill-white',
        iconFill === 'black' && '!fill-black',
        iconFill === 'rust' && '!fill-ui-rust',
        iconClassName,
        // 'my-2',
        // color === 'black' && 'fill-black',
        // color === 'v1-technique-blue' && 'fill-v1-technique-blue',
        // color === 'v1-technique-rust' && 'fill-v1-technique-rust',
        // color === 'v1-technique-green' && 'fill-v1-technique-green',
      )}
      width="100%"
      height="100%"
    />
  </div>
);
export default Icon;
