import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { v4 as uuid } from 'uuid';

import { useAuthenticatedUser, useAuthenticateUserOnLegacy } from '@lib/authentication';
import { useLocale } from '@lib/contexts/LocaleContext';
import useAnalytics from '@lib/hooks/useAnalytics';
import { doQuery, doQueryWithApiKey } from '@lib/query';
import { GET_CLOUDFRONT_COOKIES } from '@pages/cloudfrontGql';
import { AnalyticsEvent } from '@root/constants';

import { CREATE_EVENT } from './PageLayout.mutation';

export const NMH_LAUNCH_DATE = new Date('2024-12-12');

export const usePageLayout = () => {
  const [ , setCookie, removeCookie ] = useCookies();
  const [ hasSetCFCookies, setHasSetCFCookies ] = useState<boolean>(false);
  const [ hasNmhAccess, setHasNmhAccess ] = useState<boolean | undefined>(undefined);
  const { isAuthenticated, isLoading: isLoadingAuthenticatedUser } = useAuthenticatedUser();

  const locale = useLocale();
  const { canRedirectToCollections } = useAuthenticateUserOnLegacy();
  usePageLayoutEvents({ setHasNmhAccess });

  const collectionsUrl = `${import.meta.env.VITE_OMGYES_SERVER_URL}${locale.length > 0 && !locale.startsWith('en') ? `/${locale}` : ''}/collections${location.search}`;

  const handleCloudFrontCookies = async (): Promise<void> => {
    const data = await doQuery({
      query: GET_CLOUDFRONT_COOKIES,
      transformer: 'getCloudfrontCookies',
    });

    (data || []).map((cookie: string) => {
      removeCookie(cookie.split('=')[0]);
      setCookie(cookie.split('=')[0], cookie.split('=').slice(1).join('='));
    });

    setHasSetCFCookies(true);
  };

  useEffect(() => {
    localStorage.removeItem('si');
    localStorage.removeItem('ck');
  }, []);

  useEffect(() => {
    if (isLoadingAuthenticatedUser || !isAuthenticated) {
      return;
    }

    handleCloudFrontCookies();
  }, [
    isAuthenticated, isLoadingAuthenticatedUser, removeCookie, setCookie,
  ]);

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [ location ]);

  useEffect(() => {
    if (
      (hasNmhAccess === false || (locale && !locale.startsWith('en'))) &&
      canRedirectToCollections &&
      hasSetCFCookies
    ) {
      setCookie('sessionStart', new Date().toISOString());
      window.location.href = collectionsUrl;
    } else if (hasNmhAccess) {
      // setSegmented(true);
      removeCookie('showWelcomeText');
    }
  }, [ hasNmhAccess, canRedirectToCollections, hasSetCFCookies ]);

  return { hasNmhAccess };
};

const usePageLayoutEvents = ({
  setHasNmhAccess,
}: {
  setHasNmhAccess: (value: boolean) => void;
}) => {
  const [ , setCookie ] = useCookies();
  const searchParams = new URLSearchParams(location.search);
  const { analytics } = useAnalytics();
  const { authenticatedUser } = useAuthenticatedUser();
  const locale = useLocale();

  const eventsDates = useMemo(
    () => ({
      firstNmhAccess: authenticatedUser?.customOmgYesProperties.events.find(
        x => x.event === 'ACCESS_NMH',
      )?.createdAt,
      controlNmhExperiment: authenticatedUser?.customOmgYesProperties.events.find(
        x => x.event === 'CONTROL_NMH_EXPERIMENT',
      )?.createdAt,
    }),
    [ authenticatedUser ],
  );

  const sendEvent = async ({ event, id, email }: { event: string; id: string; email: string }) => {
    await doQueryWithApiKey({
      query: CREATE_EVENT,
      variables: {
        data: {
          id: uuid(),
          event,
          user: {
            connectOrCreate: {
              where: {
                id,
              },
              create: {
                id,
                email,
              },
            },
          },
        },
      },
    });
  };

  useEffect(() => {
    const handleNmhAccess = async () => {
      // still allowing forcing nmh so we can test non-english langs
      if (searchParams.get('ug') === 'nmh') {
        setHasNmhAccess(true);
        return;
      }

      const dateAccountCreated = new Date(authenticatedUser?.customOmgYesProperties.dateCreated || '');
      const preNmhAccount = !isNaN(dateAccountCreated.getTime()) && dateAccountCreated < NMH_LAUNCH_DATE;

      // Check if user lacks full access (doesn't have all seasons or non-English locale)
      const entitlements = authenticatedUser?.customOmgYesProperties?.entitlements || [];
      const ownsEverything = entitlements.length >= 3;

      if (!ownsEverything || !locale?.startsWith('en')) {
        setHasNmhAccess(false);
        return;
      }

      const hasSeenNmh = eventsDates.firstNmhAccess;
      // if they haven't accessed nmh before, are not in the control group, and are not a preNmhAccount then we know they're a new user
      const isNewUser = !preNmhAccount && !eventsDates.controlNmhExperiment;

      // We don't want to show the popup to anyone that has seen it before or any new users
      if (hasSeenNmh || isNewUser) {
        setCookie('_nmhPromo', 'd', {
          domain: '.omgyes.com',
          expires: new Date('9999-12-31T23:59:59.000Z'),
        });
      }

      // Handle former control group users who are seeing NMH for the first time
      if (eventsDates.controlNmhExperiment && !eventsDates.firstNmhAccess) {
        // Track first NMH access for former control group
        await sendEvent({
          event: 'ACCESS_NMH',
          id: authenticatedUser?.sub || '',
          email: authenticatedUser?.email || '',
        });

        if (authenticatedUser?.email) {
          await analytics.track({
            event: AnalyticsEvent.FIRST_NMH_ACCESS,
            props: {
              email: authenticatedUser.email,
              first_access_date: Date.now().toString(),
              pre_nmh_account: preNmhAccount.toString(),
              locale: locale,
            },
          });
        }
      }

      setHasNmhAccess(true);
    };

    handleNmhAccess();
  }, [
    eventsDates, authenticatedUser, locale, searchParams,
  ]);
};
