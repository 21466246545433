import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import clsx from 'clsx';

import { useLocale } from '@lib/contexts/LocaleContext';
import { LanguageLabels, LegalLinks, UtilityLinks } from '@root/constants.ts';

import { siteLink } from '@/helpers';

const PageFooter: React.FC<{ bgColor?: string; textColor?: string }> = ({ bgColor, textColor }) => {
  const { t } = useTranslation();
  const [ searchParams ] = useSearchParams();
  const locale = useLocale();

  // Remove the existing 'lng' query parameter so we don't end up with more than one in URL
  searchParams.delete('lng');

  // Convert searchParams to string, preserving existing query parameters
  const queryString = searchParams.toString();
  const queryParamString = queryString ? `&${queryString}` : '';

  const languageLinks = Object.entries(LanguageLabels).map(([ key, label ]) => ({
    label,
    url: `?lng=${key.toLowerCase()}${queryParamString}`, // Append existing query params
    // url: `${import.meta.env.VITE_OMGYES_SERVER_URL}/${key.toLowerCase()}/collections?${queryParamString}`,
    active: key === locale,
  }));

  if (localStorage.getItem('si')) {
    return null;
  }

  return (
    <footer className="mt-auto backdrop-blur-none">
      <div
        className={clsx(
          'w-full',
          'section-p-x !py-16px',
          bgColor ?? 'bg-ui-tbd2',
          textColor ?? 'text-ui-coal',
          'text-body-4',
        )}
      >
        <div
          className={clsx(
            'flex flex-wrap justify-center',
            'space-x-24px gap-y-16px',
            'px-0 md:px-32px lg:px-0',
          )}
        >
          {languageLinks.map(({ label, url, active }, index) => (
            <a
              className={clsx(
                active ? textColor || 'text-ui-pepper' : textColor ? textColor : 'text-ui-coal',
                active ? 'underline' : ' hover:underline',
              )}
              href={url}
              key={index}
            >
              {label}
            </a>
          ))}
        </div>
      </div>

      <div
        className={clsx(
          'w-full',
          'section-p-x !py-20px',
          '!text-ui-coal text-body-4',
          bgColor ?? 'bg-ui-sea-salt',
          'space-y-4px',
        )}
      >
        <div
          className={clsx(
            'flex flex-wrap justify-center pb-4px text-center',
            '[&>*]:px-8px sm:[&>*]:px-12px [&>*]:pb-16px',
          )}
        >
          {[ ...UtilityLinks, ...LegalLinks ].map(({ label, url }, index) => (
            <a
              className={clsx(textColor ? textColor : 'text-ui-coal', 'hover:underline')}
              href={siteLink(url)}
              key={index}
            >
              {t(label)} {/* Use translation for the label */}
            </a>
          ))}
        </div>
        <div className={clsx('text-body-4 text-center')}>
          <p className={clsx('text-body-4 opacity-25', textColor ?? '!text-ui-coal')}>
            &copy;{t('footer.copyright')}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default PageFooter;
