import React from 'react';

import ButtonCta from '@atoms/ButtonCta.tsx';

interface NmhPromoModalProps {
  onClose: () => void;
}

const NmhPromoModal: React.FC<NmhPromoModalProps> = ({ onClose }) => {
  return (
    <>
      <div className="fixed inset-0 z-[101] bg-black bg-opacity-80 overflow-y-auto px-16px">
        <div className="relative w-full max-w-[472px] mx-auto mt-[75px] sm:mt-[100px] md:mt-[9vh] bg-[#f6f3f0] rounded-xl overflow-hidden">
          <div className="image-banner relative w-full h-[184px] overflow-hidden">
            <button
              className="close-button text-ui-pepper text-[40px] font-light absolute top-[8px] right-[8px] leading-[20px] z-10"
              onClick={onClose}
            >
              &times;
            </button>
            <img
              className="h-full w-auto object-cover object-center min-w-full"
              src="/members/img/nmh-promo-top-image.jpg"
              alt="New Member Home Banner"
            />
          </div>
          <div className="py-8 px-6">
            <h2 className="mb-2 text-24px leading-normal">New at OMGYES!</h2>
            <p className="mb-4 font-mabry-pro-bold text-ui-coal text-16px leading-6">
              Fresh content and a new format:
            </p>
            <ul className="mb-4 article-video-description-list">
              <li className="article-video-description-list-item ml-6 text-ui-coal text-16px leading-6">
                <span className="font-mabry-pro-bold">Date Nights:</span>{' '}
                <span className="font-mabry-pro-regular">
                  sensual (and practical) activities to inspire you to connect and explore together.
                </span>
              </li>
              <li className="article-video-description-list-item ml-6 text-ui-coal text-16px leading-6">
                <span className="font-mabry-pro-bold">Bite-size topics:</span>{' '}
                <span className="font-mabry-pro-regular">
                  dive into new ideas on topics you&apos;ve asked for.
                </span>
              </li>
            </ul>
            <p className="text-ui-coal text-16px leading-6 font-mabry-pro-bold">
              Looking for your favorite techniques?
            </p>
            <p className="mb-8 text-ui-coal text-16px leading-6">
              Rediscover them under &quot;Your collections.&quot;
            </p>
            <ButtonCta onClick={onClose}>
              <span className="text-16px">Start exploring</span>
            </ButtonCta>
          </div>
        </div>
      </div>
    </>
  );
};

export default NmhPromoModal;
