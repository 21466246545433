import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom/client';
import { RecoilRoot } from 'recoil';

import { Provider as AppProvider } from '@lib/contexts/AppContext';
import { GaProvider } from '@lib/contexts/GaContext.tsx';
import { ScreenProvider } from '@lib/contexts/ScreenProvider.tsx';
import { SegmentProvider } from '@lib/contexts/SegmentContext.tsx';
import { VideoProvider } from '@lib/contexts/VideoContext.tsx';

import './i18n.ts';

import App from './App.tsx';

import './styles/globals.css';

function throwIfSSR() {
  throw new Error('Using GA during SSR is not allowed');
}

function gaHandler(...args: any[]) {
  const dataLayer = ((window as any).dataLayer = (window as any).dataLayer || []);

  dataLayer.push(args);
}

const country = 'US';
const ga = typeof window === 'undefined' ? throwIfSSR : gaHandler;

ReactDOM.createRoot(document.getElementById('root')!).render(
  // <React.StrictMode>
  <RecoilRoot>
    <AppProvider country={country} bannerActive={false}>
      <GaProvider value={ga}>
        <SegmentProvider>
          <VideoProvider>
            <CookiesProvider
              defaultSetOptions={{
                path: '/',
                domain: '.omgyes.com',
                sameSite: 'lax',
              }}
            >
              <ScreenProvider>
                <App />
              </ScreenProvider>
            </CookiesProvider>
          </VideoProvider>
        </SegmentProvider>
      </GaProvider>
    </AppProvider>
  </RecoilRoot>,
  // </React.StrictMode>,
);
