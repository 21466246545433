import React, { useEffect, useRef } from 'react';
import { createWidget } from '@typeform/embed';

interface TypeformReferralModalProps {
  onClose: () => void;
  userEmail: string;
}

const TypeformReferralModal: React.FC<TypeformReferralModalProps> = ({ onClose, userEmail }) => {
  const typeformRef = useRef(null);

  useEffect(() => {
    if (typeformRef.current) {
      createWidget('esjHqYKG', {
        container: typeformRef.current,
        hideFooter: true,
        hideHeaders: true,
        opacity: 0,
        // buttonText: 'Take the survey!',
        onSubmit: onClose,
        onClose,
        inlineOnMobile: true,
        width: '100%',
        height: '100%',
        iframeProps: { style: 'width: 100%; height: 100%;' },
        hidden: {
          email: userEmail || 'undefined',
        },
      });
    }
  }, [ userEmail, onClose ]);

  return (
    <>
      <div className={'fixed inset-0 z-[110] bg-black bg-opacity-60'}>
        <div
          className={
            'absolute bg-[#f6f3f0] z-[115] w-full h-full sm:max-w-[550px] sm:max-h-[83vh] sm:left-1/2 sm:-translate-x-1/2 sm:top-[100px] md:max-h-[85vh] md:top-[9vh]'
          }
        >
          <div className={'image-banner h-[160px] w-full relative'}>
            <button
              className="close-button text-gray-400 text-[40px] font-light absolute top-[6px] right-[10px] leading-[20px] z-10"
              onClick={onClose}
            >
              &times;
            </button>
            <img
              src="/members/img/typeform-referral-banner_v2.jpg"
              className="w-full"
              alt="Typeform Referral Banner"
            />
          </div>
          <div
            className="typeform-referral-widget w-full"
            style={{ height: 'calc(100% - 160px)' }}
            ref={typeformRef}
          />
        </div>
      </div>
    </>
  );
};

export default TypeformReferralModal;
