import { useNavigate } from 'react-router';
import { useQueryClient } from '@tanstack/react-query';

import { identityProviderMapError, isIdentityProviderError } from '../../identity-provider-error';
import { Auth } from '../../isomorphic-auth/isomorphic-auth';
import { authenticationQueryKey } from '../../queries/authentication-query-keys';

import type {
  ConfirmResetPasswordProps,
  SignInProps,
  UpdatePasswordProps,
} from './use-authentication.types';

export const useAuthentication = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const signIn = async ({ email, password }: SignInProps) => {
    try {
      const result = await Auth.signIn({ username: email, password });

      if (result.success) {
        return navigate(`/home${location.search}`, { state: { fromLogin: true }});
      }

      throw result.error;
    } catch (error) {
      if (isIdentityProviderError(error)) {
        throw identityProviderMapError(error);
      }
      throw error;
    }
  };

  const signOut = async () => {
    try {
      const result = await Auth.signOut();

      if (result.success) {
        queryClient.removeQueries({ queryKey: authenticationQueryKey.authentication() });
        return;
      }

      throw result.error;
    } catch (error) {
      if (isIdentityProviderError(error)) {
        throw identityProviderMapError(error);
      }
      throw error;
    }
  };

  const resetPassword = async (email: string) => {
    try {
      const result = await Auth.resetPassword({ username: email });

      if (result.success) {
        return;
      }

      throw result.error;
    } catch (error) {
      console.log(error);
      if (isIdentityProviderError(error)) {
        throw identityProviderMapError(error);
      }
      throw error;
    }
  };

  const confirmResetPassword = async ({
    email,
    confirmationCode,
    newPassword,
  }: ConfirmResetPasswordProps) => {
    try {
      const result = await Auth.confirmResetPassword({
        username: email,
        confirmationCode,
        newPassword,
      });

      if (result.success) {
        return;
      }

      throw result.error;
    } catch (error) {
      if (isIdentityProviderError(error)) {
        throw identityProviderMapError(error);
      }
      throw error;
    }
  };

  const updatePassword = async (props: UpdatePasswordProps) => {
    try {
      const result = await Auth.updatePassword(props);

      if (result.success) {
        return;
      }

      throw result.error;
    } catch (error) {
      if (isIdentityProviderError(error)) {
        throw identityProviderMapError(error);
      }
      throw error;
    }
  };

  return { signIn, signOut, resetPassword, confirmResetPassword, updatePassword };
};
