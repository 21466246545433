import type { TypeArticleScalingImages } from '@types.ts';

import { selectImageFromContentfulImages } from '@lib/helpers';

export const ArticleScalingImages = ({ fields }: { fields: TypeArticleScalingImages }) => {
  return (
    <div className={'article-scaling-images'} style={{ background: fields.backgroundColor }}>
      <div className={'article-scaling-image-xs'}>
        <img src={selectImageFromContentfulImages(fields.images, 360)?.fields.file.url} />
      </div>
      <div className={'article-scaling-image-ss'}>
        <img src={selectImageFromContentfulImages(fields.images, 456)?.fields.file.url} />
      </div>
      <div className={'article-scaling-image-sm'}>
        <img src={selectImageFromContentfulImages(fields.images, 640)?.fields.file.url} />
      </div>
      <div className={'article-scaling-image-md'}>
        <img src={selectImageFromContentfulImages(fields.images, 768)?.fields.file.url} />
      </div>
      <div className={'article-scaling-image-lg'}>
        <img src={selectImageFromContentfulImages(fields.images, 1024)?.fields.file.url} />
      </div>
      <div className={'article-scaling-image-xl'}>
        <img src={selectImageFromContentfulImages(fields.images, 1280)?.fields.file.url} />
      </div>
    </div>
  );
};
