import _ from 'lodash';

export function hexToRgbA(hex: string, alpha: number): string {
  if (!/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    throw new Error(`Bad Hex: ${hex}`);
  }
  const c = hex.substring(1).split('');
  let d: any;
  if (c.length == 3) {
    d = '0x' + [
      c[0], c[0], c[1], c[1], c[2], c[2], 
    ].join('');
  } else {
    d = '0x' + c.join('');
  }
  return 'rgba(' + [ (d >> 16) & 255, (d >> 8) & 255, d & 255 ].join(',') + `,${alpha})`;
}

export function keep(arr: any[], ...props: any[]) {
  return _.map(arr, _.partialRight(_.pick, ...props));
}

export const formatPrice = ({ amount, currency }: { amount: number; currency: string }) => {
  const formatter = new Intl.NumberFormat('default', {
    style: 'currency',
    currency,
    maximumFractionDigits: 0,
  });
  return formatter.format(amount);
};
