import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const formatDateForDatePicker = (d: Date | null | undefined) => {
  if (!d) return undefined;
  const shortDate = d.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
  });

  const today = new Date();
  if (d.toDateString() === today.toDateString()) {
    return `Today, ${shortDate}`;
  } else {
    return `${d.toLocaleDateString('en-US', { weekday: 'long' })}, ${shortDate}`;
  }
};
