import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { Navigate } from 'react-router-dom';
import _ from 'lodash';

import { Form, FormError, FormInput, FormSubmit } from '@components/forms';

import { AuthenticationErrors, useAuthentication } from '@lib/authentication';
import { defaultErrorMessages } from '@pages/Auth/LoginForm';

import { ExclamationCircle } from '@/components/atoms/svg/icons/ExclamationCircle';

interface ValidatorConfig {
  hint?: string;
  field?: string;
  message?: string;
  title: string;
}

const ValidatorType: Record<string, ValidatorConfig> = {
  'change-password': {
    hint: 'password.reset.currentPassword',
    field: 'password',
    message: 'password.reset.incorrectCurrentPassword',
    title: 'header.menu.changePassword',
  },
  'reset-password': {
    hint: 'password.reset.code',
    field: 'code',
    message: 'password.reset.invalidCode',
    title: 'password.reset.title',
  },
  'set-password': {
    title: 'password.reset.emptyNewPassword',
  },
};

export const PasswordFormMode = Object.keys(ValidatorType);

export interface PasswordFormProps {
  mode: 'set-password' | 'reset-password' | 'change-password';
  callback?: (newPassword: string) => any;
  email?: string | null;
  code?: string | null;
}

const PasswordForm = ({ mode, email, code, callback }: PasswordFormProps) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation();
  const { register, handleSubmit, getValues, setValue } = useForm();
  const [ errors, setErrors ] = useState<Record<string, any>>({});
  const { confirmResetPassword, signIn, updatePassword } = useAuthentication();
  const [ isSubmitting, setIsSubmitting ] = useState(false);

  const validator = mode && ValidatorType[mode];

  const onSubmit = async (data: Record<string, string>) => {
    try {
      setIsSubmitting(true);
      switch (mode || 'set-password') {
        case 'set-password':
          await callback!(data.newPassword);
          return navigate('/');

        case 'change-password':
          await updatePassword({ oldPassword: data.password, newPassword: data.newPassword });
          return navigate('/');

        case 'reset-password':
          await confirmResetPassword({
            email: email!,
            confirmationCode: data.code,
            newPassword: data.newPassword,
          });
          await signIn({ email: email!, password: data.newPassword });
          await callback!(data.newPassword);
      }
    } catch (e) {
      if (e instanceof Error) {
        if (
          e.message === AuthenticationErrors.INVALID_CODE ||
          e.message === AuthenticationErrors.CODE_EXPIRED
        ) {
          setErrors(prev => ({
            ...prev,
            code: {
              type: 'custom',
              message: (
                <span dangerouslySetInnerHTML={{ __html: t('password.reset.tokenExpired') }} />
              ),
            },
          }));
          return;
        }

        if (e.message === AuthenticationErrors.LIMIT_EXCEEDED_EXCEPTION) {
          setErrors(prev => ({
            ...prev,
            code: { type: 'custom', message: t('js.checkout.serverError') },
          }));
          return;
        }
        if (
          e.message === AuthenticationErrors.INVALID_PARAMETER ||
          e.message === AuthenticationErrors.INVALID_PASSWORD
        ) {
          setErrors(prev => ({
            ...prev,
            newPassword: {
              type: 'custom',
              message: (
                <span dangerouslySetInnerHTML={{ __html: t('password.reset.invalidPassword') }} />
              ),
            },
          }));
          return;
        }
      }

      // //TODO@WAR need custom error message for CodeMismatchException, LimitExceededException
      console.error('Uncaught reset password exception ', e);

      setErrors(errors => ({ ...errors, submit: e }));
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!email) {
    <Navigate to="/forgot-password" />;
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit, setErrors)}>
      <div className="w-full font-mabry-pro-bold text-32px tracking-tight">
        {t(validator.title)}
      </div>
      {state?.showResetPasswordEmailNotification && (
        <div className="w-full m-4">
          <div className="text-lg font-bold capitalize">{t('forgotPasswordSuccess.emailSent')}</div>
          <p className="mb-16px text-16px text-[#545251]">
            <Trans i18nKey="forgotPasswordSuccess.reset" email={email}>
              We just sent an email to{' '}
              <span className="font-semibold break-keep">{{ email } as any}</span> that contains a
              link to reset your password.
            </Trans>
          </p>
          <p className="text-16px text-[#545251]">{t('forgotPasswordSuccess.expire')}</p>
        </div>
      )}

      <FormError hidden={_.isEmpty(errors)}>
        <ul>
          {[
            'code', 'newPassword', 'confirmPassword', 'submit', 
          ].map(
            (f: string) =>
              errors[f] && (
                <li key={f}>
                  <ExclamationCircle className="mr-4px inline min-h-14px min-w-14px" />
                  {errors[f].message || t(defaultErrorMessages[f])}
                </li>
              ),
          )}
        </ul>
      </FormError>
      <div className="my-32px w-full">
        {validator?.field && (
          <FormInput
            register={() => register(validator.field!, { required: true })}
            className="mb-4 w-full"
            label={t(validator.hint!)}
            error={errors[validator.field]}
            errorClassName={'hidden'}
            type={validator.field === 'code' ? 'number' : 'password'}
            onChange={() => setErrors({})}
            defaultValue={code || ''}
          />
        )}
        <FormInput
          register={() => register('newPassword', { required: true })}
          className="mb-4 w-full"
          label={t('password.reset.newPassword')}
          error={errors.newPassword}
          errorClassName={'hidden'}
          type="password"
          onChange={e => {
            setValue('newPassword', e.target.value);
            setErrors({});
          }}
        />
        <FormInput
          className=" mb-4 w-full"
          label={t('password.reset.confirmPassword')}
          error={errors.confirmPassword}
          errorClassName={'hidden'}
          type="password"
          onChange={e => {
            setValue('confirmPassword', e.target.value);
            setErrors({});
          }}
          register={() =>
            register('confirmPassword', {
              required: true,
              deps: [ 'newPassword' ],
              validate: confirmPassword =>
                getValues('newPassword') === confirmPassword ||
                t(defaultErrorMessages.confirmPassword),
            })
          }
        />
      </div>
      <FormSubmit disabled={isSubmitting} value={t('label.submit')} />
    </Form>
  );
};

export default PasswordForm;
