import type { TypeContentfulImage } from '@types.ts';

/**
 *
 */
export const noop = () => null;

const getImageSize = (entry: TypeContentfulImage) => {
  return parseInt(entry?.fields?.title?.split('|')?.pop()?.trim() || '0');
};

export const selectImageFromContentfulImages = (images: TypeContentfulImage[], maxSize: number) => {
  return (images || [])
    .filter(x => getImageSize(x) <= maxSize)
    .sort((a, b) => {
      return getImageSize(a) < getImageSize(b) ? -1 : 1;
    })
    .pop();
};
