import React from 'react';
import type { Article, DateNightData } from '@types.ts';
import type { ContentfulClientApi, EntryQueries } from 'contentful';
import * as contentful from 'contentful';
import _ from 'lodash';

import { NMH_LOOKUP, PAGES_LOOKUP } from '@/config';

interface IContentfulContext {
  client: ContentfulClientApi<undefined>;
  getEntry: (id: string, options?: EntryQueries<undefined> | undefined) => any;
  getArticlePageId: (title: string) => Promise<string>;
  getDateNightId: (title: string) => Promise<string>;
  getPageId: (title: string) => Promise<string>;
}

const ContentfulContext = React.createContext<IContentfulContext | undefined>(undefined);

interface IContentfulProvider {
  children?: React.ReactNode;
}

export const ContentfulProvider: React.FC<IContentfulProvider> = ({ children }) => {
  const cache: { [key: string]: any } = {};

  const client = contentful.createClient({
    space: import.meta.env.VITE_CONTENTFUL_SPACE_ID!,
    accessToken: import.meta.env.VITE_CONTENTFUL_ACCESS_TOKEN!,
  });

  const getEntry = async (id: string, options?: EntryQueries<undefined> | undefined) => {
    if (_.get(cache, id)) {
      return _.get(cache, id);
    }
    cache[id] = await client.getEntry(id, options);
    return cache[id];
  };

  const getPageId = async (title: string) => {
    return PAGES_LOOKUP[title];
  };

  const getArticlePageId = async (title: string) => {
    await getEntry(NMH_LOOKUP['en']);
    const matched = _.get(cache, NMH_LOOKUP['en']).fields.articles.filter(
      (article: Article) =>
        article.fields.slug === title || _.kebabCase(article.fields.articleHeading) === title,
    );
    if (matched.length === 1) return matched[0].sys.id;
    return undefined;
  };

  const getDateNightId = async (title: string) => {
    await getEntry(NMH_LOOKUP['en']);
    const matched = _.get(cache, NMH_LOOKUP['en']).fields.dateNights.filter(
      (dateNight: DateNightData) =>
        dateNight.fields.slug === title || _.kebabCase(dateNight.fields.title) === title,
    );
    if (matched.length === 1) return matched[0].sys.id;
    return undefined;
  };

  return (
    <ContentfulContext.Provider
      value={{ client, getEntry, getArticlePageId, getDateNightId, getPageId }}
    >
      {children}
    </ContentfulContext.Provider>
  );
};

// Create an analytics hook that we can use with other components.
export const useContentful = () => {
  const result = React.useContext(ContentfulContext);

  if (!result) {
    throw new Error('Context used outside of its Provider!');
  }
  return result;
};
