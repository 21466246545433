import type { TypeArticleAnimation } from '@types.ts';

import type { IVideoPlayer } from '@components/video/VideoPlayer.tsx';
import VideoPlayer from '@components/video/VideoPlayer.tsx';

export interface IVideoMedia extends IVideoPlayer {}

export const ArticleAnimation = ({ fields }: { fields: TypeArticleAnimation }) => {
  const { animation } = fields;

  const media: IVideoMedia = {
    id: animation.fields.title,
    url: animation.fields.file.url,
    aspectRatio: fields.aspectRatio || 'default',
    videoDisplayStyle: { backgroundColor: 'transparent' },
    controls: false,
    fullFrameControls: true,
    loop: true,
    autoPlay: true,
    active: true,
  };

  return (
    <div className={'article-animation'}>
      {fields.headline && <div className={'article-animation-headline'}>{fields.headline}</div>}
      <div className={'article-animation-video-content'}>
        <VideoPlayer
          {...(media as IVideoMedia)}
          className="rounded-md border-collapse overflow-hidden video-block"
        />
      </div>
      {(fields.leftText || fields.rightText) && (
        <div className={'article-animation-text-boxes'}>
          <div className={'basis-1/2-gap-16'}>{fields.leftText}</div>
          <div className={'basis-1/2-gap-16'}>{fields.rightText}</div>
        </div>
      )}
    </div>
  );
};
