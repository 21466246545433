import type { ReactNode } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import type { Block, Inline } from '@contentful/rich-text-types';
import { BLOCKS } from '@contentful/rich-text-types';
import type { Document } from '@contentful/rich-text-types/dist/types/types';
import type {
  ArticleData,
  TypeArticleAnimation,
  TypeArticleCarouselIllustration,
  TypeArticleCarouselQuotes,
  TypeArticleData,
  TypeArticleOrbitingModule,
  TypeArticleScalingImages,
  TypeArticleSimpleIllustration,
  TypeArticleVideo,
  TypeSkeletonArticleAnimation,
  TypeSkeletonArticleCarouselIllustration,
  TypeSkeletonArticleCarouselQuotes,
  TypeSkeletonArticleOrbitingModule,
  TypeSkeletonArticleScalingImages,
  TypeSkeletonArticleSimpleIllustration,
  TypeSkeletonArticleVideo,
} from '@types.ts';
import clsx from 'clsx';
import type { Entry } from 'contentful';
import _ from 'lodash';

import { ArticleAnimation } from '@components/ArticleAnimation.tsx';
import { ArticleCarouselIllustrations } from '@components/ArticleCarouselIllustrations.tsx';
import { ArticleCarouselQuotes } from '@components/ArticleCarouselQuotes.tsx';
import { ArticleOrbitingModule } from '@components/ArticleOrbitingModule.tsx';
import { ArticleScalingImages } from '@components/ArticleScalingImages.tsx';
import { ArticleSelector } from '@components/ArticleSelector.tsx';
import { ArticleSimpleIllustrations } from '@components/ArticleSimpleIllustrations';
import { ArticleVideo } from '@components/ArticleVideo.tsx';
import AppPageHeader from '@components/common/AppPageHeader.tsx';
import PageFooter from '@components/common/PageFooter.tsx';

import { useContentful } from '@lib/contexts/ContentfulContext';
import { selectImageFromContentfulImages } from '@lib/helpers';
import useAnalytics from '@lib/hooks/useAnalytics.ts';
import useContentfulLive from '@lib/hooks/useContentfulLive.ts';
import useScrollDepthTracker from '@lib/hooks/useScrollDepthTracker';
import { getDateNightPath, navigateToLegacyTechnique } from '@lib/links.ts';
import { AnalyticsEvent } from '@root/constants.ts';

const ArticlePage = () => {
  const { getEntry, getArticlePageId } = useContentful();
  const params = useParams();
  const [ publishedData, setPublishedData ] = useState<TypeArticleData>();
  const { analytics } = useAnalytics();
  const [ lastPage, setLastPage ] = useState<string>();
  const [ headerImageLoaded, setHeaderImageLoaded ] = useState<boolean>(false);

  const sectionRefs = useRef<HTMLDivElement[]>([]);
  const navigate = useNavigate();

  useScrollDepthTracker();

  useEffect(() => {
    if (params.id && lastPage !== params.id) {
      window.scrollTo({ top: 0 });
      setLastPage(params.id);
    }
  }, [ params.id ]);

  useEffect(() => {
    analytics.page({ page: 'article_page', props: params });
  }, []); //removing dependencies to ensure this only fires once when the component mounts

  useEffect(() => {
    const fetchPage = async (title: string) => {
      if (params.id) {
        const id = await getArticlePageId(title);
        if (id) {
          getEntry(id, { include: 4 }).then((data: TypeArticleData) => {
            setPublishedData(data);
          });
        } else {
          navigate('/home');
        }
      }
    };
    params.id && fetchPage(params.id);
  }, [ getEntry, params.id ]);

  const { data: fullData } = useContentfulLive(publishedData);
  const data: ArticleData = fullData?.fields as ArticleData;

  const renderDescription = (description: Document) => {
    return (
      <div className="article-section-description">
        {documentToReactComponents(description, {
          renderNode: {
            [BLOCKS.OL_LIST]: (_node: Block | Inline, children: ReactNode) => (
              <div className={'article-section-description-numbered-list-container'}>
                <ol className={clsx('article-section-description-numbered-list')}>{children}</ol>
              </div>
            ),
            [BLOCKS.UL_LIST]: (_node: Block | Inline, children: ReactNode) => (
              <div className={'article-section-description-list-container'}>
                <ul className={clsx('article-section-description-list')}>{children}</ul>
              </div>
            ),
            [BLOCKS.LIST_ITEM]: (node: Block | Inline) => {
              const transformedChildren = documentToReactComponents(node as Document, {
                // renderMark: options.renderMark,
                renderNode: {
                  [BLOCKS.PARAGRAPH]: (_node: Block | Inline, children: ReactNode) => children,
                  [BLOCKS.LIST_ITEM]: (_node: Block | Inline, children: ReactNode) => children,
                },
              });
              return (
                <li className={'article-section-description-list-item'}>{transformedChildren}</li>
              );
            },
            [BLOCKS.PARAGRAPH]: (_node: Block | Inline, children: ReactNode) => (
              <div>{children}</div>
            ),
            [BLOCKS.QUOTE]: (_node: Block | Inline, children: ReactNode) => {
              return <div className={'article-quote-block'}>{children}</div>;
            },
          },
        })}
      </div>
    );
  };

  const renderAssets = (
    assets: (
      | Entry<TypeSkeletonArticleSimpleIllustration>
      | Entry<TypeSkeletonArticleCarouselIllustration>
      | Entry<TypeSkeletonArticleScalingImages>
      | Entry<TypeSkeletonArticleVideo>
      | Entry<TypeSkeletonArticleCarouselQuotes>
      | Entry<TypeSkeletonArticleOrbitingModule>
      | Entry<TypeSkeletonArticleAnimation>
    )[],
  ) => {
    return (
      <div className={'article-section-assets'}>
        {assets?.map((asset, index: number) => {
          return (
            <div key={index}>
              {asset.sys.contentType.sys.id === 'articleSimpleIllustrations' ? (
                <div className={'article-x-padding'}>
                  <div className={'article-max768-container '}>
                    <ArticleSimpleIllustrations
                      fields={asset.fields as TypeArticleSimpleIllustration}
                    />
                  </div>
                </div>
              ) : null}
              {asset.sys.contentType.sys.id === 'articleScalingImages' ? (
                <div className={'article-x-padding'}>
                  <div className={'article-max768-container '}>
                    <ArticleScalingImages fields={asset.fields as TypeArticleScalingImages} />
                  </div>
                </div>
              ) : null}
              {asset.sys.contentType.sys.id === 'video' ? (
                <div className={'article-x-padding'}>
                  <div className={'article-max768-container '}>
                    <ArticleVideo fields={asset.fields as TypeArticleVideo} />
                  </div>
                </div>
              ) : null}
              {asset.sys.contentType.sys.id === 'articleAnimation' ? (
                <div className={'article-x-padding-animation'}>
                  <div className={'article-max768-container '}>
                    <ArticleAnimation fields={asset.fields as TypeArticleAnimation} />
                  </div>
                </div>
              ) : null}
              {asset.sys.contentType.sys.id === 'articleCarouselIllustrations' ? (
                <ArticleCarouselIllustrations
                  fields={asset.fields as TypeArticleCarouselIllustration}
                />
              ) : null}
              {asset.sys.contentType.sys.id === 'articleCarouselQuotes' ? (
                <ArticleCarouselQuotes fields={asset.fields as TypeArticleCarouselQuotes} />
              ) : null}
              {asset.sys.contentType.sys.id === 'articleOrbitingModule' ? (
                <div className={'article-x-padding'}>
                  <div className={'article-max768-container '}>
                    <ArticleOrbitingModule fields={asset.fields as TypeArticleOrbitingModule} />
                  </div>
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    );
  };

  if (!data || !(params.id === _.kebabCase(data.articleHeading) || params.id === data.slug)) {
    return <></>;
  }

  return (
    <div className="article">
      <AppPageHeader showLogo={true} scrolledTitle={data.articleHeading} previousPage={'/home'} />
      <div className={'flex flex-col'}>
        <div className={'z-10 absolute flex flex-row w-full article-header-image-background'}>
          <div
            className={'w-full'}
            style={{
              backgroundColor: !headerImageLoaded
                ? '#000000'
                : data.headerBackgroundColor || '#000000',
            }}
          />
          <div
            className={'w-full'}
            style={{
              backgroundColor: !headerImageLoaded
                ? '#000000'
                : data.headerBackgroundColorRight || '#000000',
            }}
          />
        </div>
        <div className={'article-header-image-container'}>
          <div className={'article-header-image-xs'}>
            <img
              src={selectImageFromContentfulImages(data.headerImages, 360)?.fields.file.url}
              className={'object-cover h-full'}
              onLoad={() => setHeaderImageLoaded(true)}
            />
          </div>
          <div className={'article-header-image-lg'}>
            <img
              src={selectImageFromContentfulImages(data.headerImages, 1024)?.fields.file.url}
              className={'object-cover h-full'}
              onLoad={() => setHeaderImageLoaded(true)}
            />
          </div>
        </div>
      </div>

      <div className="article-content">
        <div className="article-x-padding">
          <div className="article-hero-container">
            <div className="article-hero-text-container">
              <div className="article-hero-title">{data.articleHeading}</div>
              <div className="article-hero-text-container">
                <div className="article-hero-headline">{data.articleKeyLine}</div>
                <div className="article-hero-text">
                  {documentToReactComponents(data.articleDescription, {
                    renderNode: {
                      [BLOCKS.PARAGRAPH]: (_node: Block | Inline, children: ReactNode) => (
                        <div>{children}</div>
                      ),
                    },
                  })}
                </div>
              </div>
            </div>
            {/*Tools you'll get*/}
            <div className="article-hero-navigation-container">
              <div
                className="article-hero-navigation-box"
                style={{
                  background:
                    'radial-gradient(48.08% 27.20% at 43.45% 18.45%, rgba(252, 231, 205, 0.60) 0%, rgba(255, 244, 231, 0.60) 100%)',
                }}
              >
                <div className="article-hero-navigation-headline">{data.articleToolsHeading}</div>
                <div className="article-hero-navigation-links-container">
                  <ul className={clsx('article-hero-navigation-links-list')}>
                    {data.articleSections.map(({ fields }, index) => {
                      return (
                        <li
                          key={index}
                          className={'article-hero-navigation-links-list-item'}
                          onClick={() => {
                            window.scrollTo({
                              top: sectionRefs.current[index || 0]?.offsetTop - 15,
                              behavior: 'smooth',
                            });
                          }}
                        >
                          <div>{fields.toolLinkText || fields.heading}</div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {data.articleSections?.map(({ fields }, index: number) => {
          return (
            <div key={`article-section-${index}`} className={'article-section'}>
              <div className={'article-divider-padding'}>
                <div className={'article-max768-container-at-lg'}>
                  <div className={'article-divider-line'} />
                  <div
                    className={'article-divider'}
                    ref={el => (sectionRefs.current[index] = el!)}
                  />
                </div>
              </div>

              <div className={'article-section-content'}>
                <div className={'article-x-padding'}>
                  <div className={'article-max768-container '}>
                    <div className="article-section-text-container">
                      <div className="article-section-heading">{fields.heading}</div>
                      {fields.description && renderDescription(fields.description)}
                    </div>
                  </div>
                </div>

                {fields.assets && renderAssets(fields.assets)}

                {fields.description2 && (
                  <div className={'article-x-padding'}>
                    <div className={'article-max768-container '}>
                      <div className="article-section-text-container">
                        {renderDescription(fields.description2)}
                      </div>
                    </div>
                  </div>
                )}

                {fields.assets2 && renderAssets(fields.assets2)}

                {fields.readMore && (
                  <div className={'article-x-padding'}>
                    <div className={'article-max768-container '}>
                      <div className="article-section-read-more">
                        {documentToReactComponents(fields.readMore, {
                          renderNode: {
                            [BLOCKS.PARAGRAPH]: (_node: Block | Inline, children: ReactNode) => (
                              <div>{children}</div>
                            ),
                          },
                        })}
                      </div>
                    </div>
                  </div>
                )}

                {fields.readMoreTechnique && (
                  <div className={'article-x-padding'}>
                    <div className={'article-max768-container '}>
                      <div className="article-section-read-more">
                        <div
                          onClick={() => {
                            analytics.track({
                              event: AnalyticsEvent.LINK_CLICK,
                              props: { name: fields.readMoreTechnique.fields.title },
                            });
                            navigateToLegacyTechnique(fields.readMoreTechnique.fields.title);
                          }}
                        >
                          Read more about {fields.readMoreTechnique?.fields.title} {'->'}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {fields.tryADateNightGame && (
                  <div className={'article-x-padding'}>
                    <div className={'article-max768-container '}>
                      <div className="article-section-read-more">
                        <div
                          onClick={() => {
                            analytics.track({
                              event: AnalyticsEvent.LINK_CLICK,
                              props: {
                                page: getDateNightPath(fields.tryADateNightGame),
                                name: fields.tryADateNightGame?.fields.title,
                              },
                            });
                            navigate(getDateNightPath(fields.tryADateNightGame));
                          }}
                        >
                          Try the {fields.tryADateNightGame?.fields.title} game {'->'}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}

        <div className={'article-divider-padding'}>
          <div className={'article-max768-container-at-lg'}>
            <div className={'article-divider-line'} />
            <div className={'article-divider'} />
          </div>
        </div>

        <div className={'article-section-takeaways'}>
          <div className={'article-x-padding'}>
            <div className={'article-key-takeaways'}>
              <div className={'article-key-takeaways-headline'}>{data.keyTakeawaysHeading}</div>
              <div className={'article-key-takeaways-list-container'}>
                {documentToReactComponents(data.keyTakeaways, {
                  renderNode: {
                    [BLOCKS.UL_LIST]: (_node: Block | Inline, children: ReactNode) => (
                      <ul className={clsx('article-key-takeaways-list')}>{children}</ul>
                    ),
                    [BLOCKS.LIST_ITEM]: (node: Block | Inline) => {
                      const transformedChildren = documentToReactComponents(node as Document, {
                        // renderMark: options.renderMark,
                        renderNode: {
                          [BLOCKS.PARAGRAPH]: (_node: Block | Inline, children: ReactNode) =>
                            children,
                          [BLOCKS.LIST_ITEM]: (_node: Block | Inline, children: ReactNode) =>
                            children,
                        },
                      });
                      return (
                        <li className={'article-key-takeaways-list-item'}>
                          <div>{transformedChildren}</div>
                        </li>
                      );
                    },
                    [BLOCKS.PARAGRAPH]: (_node: Block | Inline, children: ReactNode) => children,
                  },
                })}
              </div>
            </div>
          </div>
          <div>
            <ArticleSelector currentArticle={params.id} />
            <div className={'article-return-to-home-container'}>
              <div
                className={'article-return-to-home'}
                onClick={() => navigate('/home', { preventScrollReset: false })}
              >
                <div className={'h-full'}>
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.5889 13.0139C10.8037 12.8078 10.8037 12.4736 10.5889 12.2675L6.57782 8.41846L10.5889 4.56943C10.8037 4.36332 10.8037 4.02915 10.5889 3.82304C10.3741 3.61693 10.0259 3.61693 9.81109 3.82304L5.41109 8.04526C5.1963 8.25137 5.1963 8.58554 5.41109 8.79165L9.81109 13.0139C10.0259 13.22 10.3741 13.22 10.5889 13.0139Z"
                      fill="#2973DB"
                    />
                  </svg>
                </div>
                <div className={'h-[24px] mb-0.5'}>Return to home</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="article-footer">
        <PageFooter />
      </div>
    </div>
  );
};

export default ArticlePage;
