import React, { useEffect, useState } from 'react';

interface IScreenContext {
  width: number;
}

const ScreenContext = React.createContext<IScreenContext | undefined>(undefined);

interface IScreenProvider {
  children?: React.ReactNode;
}

export const ScreenProvider: React.FC<IScreenProvider> = ({ children }) => {
  const [ width, setWidth ] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <ScreenContext.Provider value={{ width }}>{children}</ScreenContext.Provider>;
};

export const useScreen = () => {
  const result = React.useContext(ScreenContext);

  if (!result) {
    throw new Error('Context used outside of its Provider!');
  }
  return result;
};
