import { useEffect, useState } from 'react';
import type { TypeArticleOrbitingModule } from '@types.ts';
import clsx from 'clsx';

export const ArticleOrbitingModule = ({ fields }: { fields: TypeArticleOrbitingModule }) => {
  const [ location, setLocation ] = useState<string>(fields?.locations[0]);
  const [ pressure, setPressure ] = useState<string>(fields?.pressures[0]);

  const [ selectedAnimation, setSelectedAnimation ] = useState<string>();

  useEffect(() => {
    if (location && pressure) {
      const animation = fields.animations.find(({ fields }) => {
        if (
          fields.title.toLowerCase().match(location.toLowerCase()) &&
          fields.title.toLowerCase().match(pressure.toLowerCase())
        ) {
          return true;
        }
        return false;
      });
      setSelectedAnimation(animation?.fields.file.url);
    }
  }, [ location, pressure, setSelectedAnimation ]);

  return (
    <div className={'article-orbiting-module'}>
      <div className={'article-orbiting-animation-container'}>
        <div
          className={'article-orbiting-animation-background'}
          style={{ backgroundColor: fields.backgroundColor }}
        />
        <div className={'article-orbiting-animation-poster'}>
          <img className={'h-full align-center mx-auto'} src={fields.poster.fields.file.url} />
        </div>
        {selectedAnimation && (
          <div className={'article-orbiting-animation-poster'}>
            <video
              key={selectedAnimation}
              className={'h-full mx-auto'}
              autoPlay={true}
              loop={true}
              playsInline={true}
              muted
            >
              <source src={selectedAnimation} />
            </video>
          </div>
        )}
      </div>
      <div className={'article-orbiting-selectors-container '}>
        <div className={'article-orbiting-selector-container'}>
          <div className={'article-orbiting-selector-heading '}>Location</div>
          <div className={'article-orbiting-selectors'}>
            {fields.locations.map((_location, index) => {
              return (
                <div
                  key={index}
                  className={clsx(
                    'article-orbiting-selector-container',
                    // index === fields.locations.length-1 ? 'flex-grow-0' : 'flex-grow'
                  )}
                  onClick={() => setLocation(_location)}
                >
                  <div
                    className={clsx(
                      'article-orbiting-selector',
                      location === _location
                        ? 'article-orbiting-selector-text-selected'
                        : 'article-orbiting-selector-text',
                    )}
                  >
                    {_location}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={'article-orbiting-selector-container'}>
          <div className={'article-orbiting-selector-heading'}>Pressure</div>
          <div className={'article-orbiting-selectors'}>
            {fields.pressures.map((_pressure, index) => {
              return (
                <div
                  key={index}
                  className={'article-orbiting-selector-container'}
                  onClick={() => setPressure(_pressure)}
                >
                  <div
                    className={clsx(
                      'article-orbiting-selector',
                      pressure === _pressure
                        ? 'article-orbiting-selector-text-selected'
                        : 'article-orbiting-selector-text',
                    )}
                  >
                    {_pressure}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
