import type { DateNightData } from '@types.ts';
import _ from 'lodash';

export function navigateToLegacyTechnique(title: string) {
  const target = `${import.meta.env.VITE_OMGYES_SERVER_URL}/collections/${_.kebabCase(title)}`;
  location.href = target;
}

export function getDateNightPath(data: DateNightData) {
  return `/date-night/${data.fields.slug || _.kebabCase(data.fields.title)}`;
}
