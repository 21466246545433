import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import type { Block, Inline } from '@contentful/rich-text-types';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import type { NewMemberHomeData } from '@types.ts';

import { useContentful } from '@lib/contexts/ContentfulContext';

import { NMH_LOOKUP } from '@/config';

export default function Help() {
  const params = useParams();
  const [ data, setData ] = useState<NewMemberHomeData>();
  const [ selectedQuestion, setSelectedQuestion ] = useState<number>();
  const [ hover, setHover ] = useState<string | number>();
  const { getEntry } = useContentful();

  useEffect(() => {
    getEntry(NMH_LOOKUP[params.locale || 'en'], {
      include: 3,
    }).then((data: NewMemberHomeData) => {
      setData(data);
    });
  }, [ params.locale ]);

  return (
    <div className={'menu-action-content'}>
      <div className={'menu-action-content-heading'}>
        <div className={'menu-action-content-headline'}>Help</div>
        <div className={'menu-action-content-description'}>
          Help us get better. Report any problems to:{' '}
          <a href={'mailto:support@omgyes.com'}>
            <span className={'text-cta'}>support@omgyes.com</span>
          </a>
        </div>
      </div>
      <div className={'mt-32px flex flex-col gap-8px pb-[80px]'}>
        {(data?.fields?.helpFaqs || []).map(({ fields }, index) => {
          return (
            <div className={'gift-page-faq-item'} key={index}>
              <div
                className={'menu-help-faq-item-question-container'}
                onMouseEnter={() => setHover(index)}
                onMouseLeave={() => setHover(undefined)}
                onClick={() => setSelectedQuestion(selectedQuestion === index ? undefined : index)}
              >
                <div
                  className={
                    selectedQuestion === index
                      ? 'menu-help-question-selected'
                      : 'menu-help-question'
                  }
                >
                  {fields.question}
                </div>
                <div
                  className={
                    selectedQuestion === index
                      ? 'text-ui-pepper'
                      : hover === index
                        ? 'text-[#AAA9A9]'
                        : 'text-ui-onion'
                  }
                >
                  {selectedQuestion === index ? (
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.25573 13.2361C4.51337 13.5046 4.93108 13.5046 5.18872 13.2361L10 8.22227L14.8113 13.2361C15.0689 13.5046 15.4866 13.5046 15.7443 13.2361C16.0019 12.9677 16.0019 12.5323 15.7443 12.2639L10.4665 6.76386C10.2089 6.49538 9.79114 6.49538 9.53351 6.76386L4.25573 12.2639C3.99809 12.5323 3.99809 12.9677 4.25573 13.2361Z"
                        fill="currentColor"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.25573 6.76386C4.51337 6.49538 4.93108 6.49538 5.18872 6.76386L10 11.7777L14.8113 6.76386C15.0689 6.49538 15.4866 6.49538 15.7443 6.76386C16.0019 7.03235 16.0019 7.46765 15.7443 7.73614L10.4665 13.2361C10.2089 13.5046 9.79114 13.5046 9.53351 13.2361L4.25573 7.73614C3.99809 7.46765 3.99809 7.03235 4.25573 6.76386Z"
                        fill="currentColor"
                      />
                    </svg>
                  )}
                </div>
              </div>
              {selectedQuestion === index && (
                <div className={'menu-help-faq-item-answer-container'}>
                  {documentToReactComponents(fields.answer, {
                    renderNode: {
                      [INLINES.HYPERLINK]: (node: any, children: any) => (
                        <a
                          href={node.data.uri}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-cta underline"
                        >
                          {children}
                        </a>
                      ),
                      [BLOCKS.PARAGRAPH]: (_node: Block | Inline, children: ReactNode) => (
                        <span>{children}</span>
                      ),
                      [BLOCKS.OL_LIST]: (_node: Block | Inline, children: ReactNode) => (
                        <div className={'ml-16px'}>
                          <ol style={{ listStyle: 'decimal', listStylePosition: 'outside' }}>
                            {children}
                          </ol>
                        </div>
                      ),
                      [BLOCKS.UL_LIST]: (_node: Block | Inline, children: ReactNode) => (
                        <div className={'ml-16px'}>
                          <ul style={{ listStyle: 'decimal', listStylePosition: 'outside' }}>
                            {children}
                          </ul>
                        </div>
                      ),
                      [BLOCKS.LIST_ITEM]: (_node: Block | Inline, children: ReactNode) => (
                        <li className={'pb-24px'}>{children}</li>
                      ),
                    },
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
