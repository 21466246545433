import React from 'react';
import type { GridApi, IRowNode } from 'ag-grid-community';
import type { CustomCellRendererProps } from 'ag-grid-react';
import _ from 'lodash';

export interface IconProviderStatus {
  isEditing: boolean;
}

interface GridOptions<TData> {
  api: GridApi;
  node: IRowNode<TData>;
}

interface ActionCellRendererProps<TData> extends CustomCellRendererProps {
  icons:
    | Record<string, (...props: any[]) => React.JSX.Element>
    | ((
        data: TData,
        status: IconProviderStatus,
      ) => Record<string, (...props: any[]) => React.JSX.Element>);
  onClick: (value: any, action: string, gridOpts: GridOptions<TData>) => any;
  api: GridApi;
  column: any;
  node: IRowNode<TData>;
}

export default function ActionCellRenderer<T>({
  api,
  column,
  node,
  icons: iconProvider,
  onClick,
}: ActionCellRendererProps<T>) {
  const isEditing = !!api
    .getEditingCells()
    .find(({ rowIndex }: { rowIndex: number }) => rowIndex === node.rowIndex);

  const icons = _.isFunction(iconProvider) ? iconProvider(node.data!, { isEditing }) : iconProvider;

  setTimeout(() => {
    api.autoSizeColumns([ column! ]);
  }, 0);

  return (
    <span>
      {Object.entries(icons).map(([ action, Icon ]) => (
        <button
          key={action}
          onClick={() => onClick(node.data, action, { api, node })}
          className="m-0.5 p-1.5 border border-slate-600 rounded bg-[#F0F8FF]"
        >
          <Icon />
        </button>
      ))}
    </span>
  );
}

export function getActionCellClickHandler<TData extends { id: any; deleted?: boolean }>(
  lookupByid: (id: any) => TData | undefined,
  newRecord: () => TData,
  updateData: (fn: (state: TData[]) => TData[]) => void,
) {
  return ({ id, originalId }: any, action: string, { api, node }: GridOptions<TData>) => {
    try {
      switch (action) {
        case 'revert': {
          const oldValue = lookupByid(originalId);
          if (oldValue) {
            updateData((rows: TData[]) =>
              rows.map((row: TData) => (row.id === id ? { ...oldValue } : row)),
            );
          } else {
            updateData(rows => rows.filter(row => row.id !== id));
          }
          return;
        }
        case 'delete':
          if (originalId) {
            updateData(rows =>
              rows.map(row =>
                row.id === id ? { ...newRecord(), id, originalId, deleted: true } : row,
              ),
            );
          } else {
            updateData(rows => rows.filter(row => row.id !== id));
          }
          return;
      }
    } finally {
      api.redrawRows({ rowNodes: [ node ]});
    }
  };
}
