import { useEffect, useState } from 'react';
// import { hotjar } from 'react-hotjar';
import type { User } from '@segment/analytics-next';

import type { AnalyticsPageTag, AnalyticsTag } from '@components/types';

// import { ENV,
// HOTJAR_DEBUG, HOTJAR_SITE_ID
// } from '@config/site';
import { useAppContext } from '@lib/contexts/AppContext';
import { useGa } from '@lib/contexts/GaContext';
import { useSegment } from '@lib/contexts/SegmentContext';
interface IUseAnalytics {
  analytics: {
    track: (tag: AnalyticsTag) => Promise<any>;
    page: (tag: AnalyticsPageTag) => Promise<any>;
    identify: () => Promise<any>;
    getSegmentUser: () => Promise<User>;
  };
}

const useAnalytics = (): IUseAnalytics => {
  const [ cookie, setCookie ] = useState<string | null>(null);
  const { analytics: segmentAnalytics } = useSegment();
  const {
    state: { experiment },
  } = useAppContext();

  const ga = useGa();

  useEffect(() => {
    // if (HOTJAR_SITE_ID) {
    //   hotjar.initialize(HOTJAR_SITE_ID, 6, HOTJAR_DEBUG);
    // }
  }, []);

  useEffect(() => {
    if (experiment?.id && experiment?.variant !== undefined) {
      setCookie(`${experiment.id}.${experiment.variant}`);
    }
  }, [ experiment?.id, experiment?.variant ]);

  useEffect(() => {
    if (ga && cookie) {
      // ga('set', 'exp', cookie);
    }
  }, [ ga, cookie ]);

  const global = {
    experiment: experiment?.name,
    experiment_id: experiment?.id,
    variant: experiment?.variant_name,
    variant_id: experiment?.variant,
    env: import.meta.env.VITE_ENV,
  };

  const page = (tag: AnalyticsPageTag) => {
    return segmentAnalytics.page(tag.page, { ...global, ...tag.props });
  };

  const track = (tag: AnalyticsTag) =>
    segmentAnalytics.track(tag.event, { ...global, ...tag.props });

  const getSegmentUser = () => segmentAnalytics.user();

  const identify = () => {
    // if ( hotjar.initialized()) {
    //   hotjar.identify(null, { ...global });
    // }

    return segmentAnalytics.identify({ ...global });
  };

  return {
    analytics: { page, track, identify, getSegmentUser },
  };
};

export default useAnalytics;
