import React from 'react';

import ButtonCta from './atoms/ButtonCta.tsx';

interface FormErrorProps {
  hidden?: boolean;
  children: React.ReactNode;
}

export const FormError: React.FC<FormErrorProps> = ({ hidden, children }) => (
  <div className={`w-full mt-4 flex rounded-lg border border-[rgba(192,18,18,0.06)] bg-[rgba(192,18,18,0.04)] ${hidden ? 'hidden' : 'flex'}`}>
    <span className={`p-2 text-[#c01212] text-sm w-full items-center ${hidden ? 'hidden' : 'block'}`}>
      {children}
    </span>
  </div>
);

export interface FormInputProps<T> extends React.InputHTMLAttributes<T> {
  error?: { message?: string };
  defaultErrorMessage?: string;
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  errorClassName?: string;
  register: () => Record<string, any>;
  name?: string;
  placeholder?: string;
  label?: string;
}

export const FormInput = ({
  error,
  defaultErrorMessage,
  className,
  labelClassName,
  inputClassName,
  errorClassName,
  register,
  ...props
}: FormInputProps<any>) => {
  const { onChange: parentOnChange, ...restProps } = props;

  const baseInputStyles = 'p-2 block w-full rounded-lg border-2 bg-[#f3f3f3]';
  const errorInputStyles = error
    ? 'border-[rgba(192,18,18,0.3)] focus:border-[rgba(192,18,18,0.3)] outline-[rgba(192,18,18,0.3)]'
    : 'border-[#F3F3F3] focus:border-[#2973db]';
  const hoverStyles = error
    ? 'hover:border-[rgba(192,18,18,0.3)] hover:bg-[#f0f0f0]'
    : 'hover:border-[#F0F0F0] hover:bg-[#f0f0f0]';
  const focusStyles = 'focus:bg-white focus:outline-none';

  return (
    <div className={className || 'w-full'}>
      <label
        className={`text-sm text-[#545251] font-normal leading-5 tracking-[0.175px] font-['MabryPro-Regular'] ${labelClassName}`}
        htmlFor={props.name}
      >
        {props.label}
      </label>
      <input
        className={`${baseInputStyles} ${errorInputStyles} ${hoverStyles} ${focusStyles} ${inputClassName}`}
        aria-invalid={!!error}
        {...restProps}
        {...register()}
        onChange={e => {
          if (parentOnChange) parentOnChange(e);
        }}
      />
      <span
        className={`p-2 text-[#c01212] text-sm w-full items-center ${!(error?.message || defaultErrorMessage) || errorClassName === 'hidden' ? 'hidden' : 'block'} ${errorClassName}`}
      >
        {error?.message || defaultErrorMessage || ''}
      </span>
    </div>
  );
};

export const FormSubmit: React.FC<any> = ({ className, disabled, ...args }) => (
  <div className="h-contain w-full">
    <ButtonCta disabled={disabled} className={className}>
      <input disabled={disabled} {...args} type="submit" />
    </ButtonCta>
  </div>
);

interface FormProps {
  children: React.ReactNode;
  className?: string;
  onSubmit?: (e?: React.BaseSyntheticEvent) => Promise<void> | void;
}

export const Form = ({ children, className, onSubmit }: FormProps) => {
  return (
    <form className={className} onSubmit={onSubmit}>
      {children}
    </form>
  );
};

export const setSubmitError = (setErrors: any, err: any) =>
  setErrors((errors: any) => ({ ...errors, submit: err }));
