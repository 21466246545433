import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useQuery } from '@tanstack/react-query';

import { useContentful } from '@lib/contexts/ContentfulContext';
import useAnalytics from '@lib/hooks/useAnalytics';
import { doQueryWithApiKey } from '@lib/query';
import type { ListProductsQueryVariables } from '@pages/checkout/checkout';
import { LIST_PRODUCTS_FOR_PURCHASE_QUERY } from '@pages/checkout/listProductsQuery';

import type { GiftPageData } from './GiftPage.types';
import type { GiftProductPricing } from './GiftPage.types';

export const useGiftPageAnalytics = () => {
  const params = useParams();
  const { analytics } = useAnalytics();

  useEffect(() => {
    analytics.page({ page: 'gift_give_page', props: params });
  }, []); // Fires once when component mounts
};

export const useGiftPageData = () => {
  const { getEntry, getPageId } = useContentful();

  return useQuery({
    queryKey: [ 'giftPage' ],
    queryFn: async () => {
      const id = await getPageId('gift');
      if (!id) {
        throw new Error('Page ID not found');
      }
      const response = await getEntry(id);
      return response.fields as GiftPageData;
    },
  });
};

export const useProductPricing = (turnstileToken?: string) => {
  const [ productPricing, setProductPricing ] = useState<GiftProductPricing>();

  useEffect(() => {
    if (!turnstileToken) return;

    (async () => {
      const variables: ListProductsQueryVariables = {
        turnstileToken,
        purchasable: true,
      };

      const results = await doQueryWithApiKey({
        query: LIST_PRODUCTS_FOR_PURCHASE_QUERY,
        variables,
        transformer: 'listProductsForPurchase',
      });

      const productPricing: GiftProductPricing = {
        explore_more: results
          .filter(({ productId }: { productId: string }) => productId === 'series,series2,series3')
          .sort((a: any) => (a.currency !== 'USD' ? -1 : 1)),
        essentials: results
          .filter(({ productId }: { productId: string }) => productId === 'series')
          .sort((a: any) => (a.currency !== 'USD' ? -1 : 1)),
      };

      if (!productPricing.explore_more || !productPricing.essentials) {
        throw new Error('Invalid product');
      }
      setProductPricing(productPricing);
    })();
  }, [ turnstileToken ]);

  return { productPricing };
};
