import type { Dispatch, ReactNode, Reducer } from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import React, { useReducer } from 'react';

// TODO: Add a type for `Action` that is a union of the specific actions
// (change on a per-context basis)
export interface Action {
  type: string;
  payload: any;
}

// this is where I am getting tricky
type BoundActions<T> = {
  [K in keyof T]: T[K] extends (d: Dispatch<Action>) => infer R ? R : never;
};

type ContextValue<T, D> = {
  state: D;
} & BoundActions<T>;

interface IProvider {
  children: ReactNode;
}

type ContextOptions = {
  persist?: boolean;
  persist_key?: string;
  persist_ignore_keys?: string[];
};
export const createDataContext = <T extends {}, D = {}, P = {}>(
  reducer: Reducer<D, Action>,
  actions: T,
  defaultValue: D,
  options?: ContextOptions,
) => {
  // context needs a defaultValue
  const Context = React.createContext({ state: defaultValue } as ContextValue<T, D>);

  // type of children is known by assigning the type FunctionComponent to Provider
  const Provider: React.FC<IProvider & P> = ({ children, ...props }) => {
    const [ state, dispatch ] = useReducer(reducer, { ...defaultValue, ...props });

    useEffect(() => {
      if (!options?.persist || !options?.persist_key) return;

      const stored = localStorage.getItem(options?.persist_key);
      if (stored) {
        const parsed = JSON.parse(stored);
        dispatch({ type: 'init_stored', payload: { ...parsed, ...props }});
        // console.log('createDataContext :: parsed -', parsed);
        // console.log('NewAppContext ::  -', JSON.parse(stored));
      }
    }, []);

    useEffect(() => {
      if (!options?.persist || !options?.persist_key) return;

      if (JSON.stringify(state) !== JSON.stringify(defaultValue)) {
        const persistState = { ...state };

        if (options.persist_ignore_keys) {
          options.persist_ignore_keys.forEach(k => delete persistState[k as keyof typeof state]);
        }

        localStorage.setItem(options?.persist_key, JSON.stringify(persistState));
      }
    }, [ state ]);

    const boundActions = useMemo<BoundActions<T>>(() => {
      const obj = {};

      for (const key in actions) {
        // @ts-ignore - I don't want to make a confusing mess so just ignore this
        obj[key] = actions[key](dispatch);
      }
      return obj as BoundActions<T>;
    }, []);

    return <Context.Provider value={{ state, ...boundActions }}>{children}</Context.Provider>;
  };

  return { Context, Provider };
};

// const authReducer = (state: DataState, action: Action): DataState => {
//   switch (action.type) {
//     default:
//       return state;
//   }
// };

// const signup = (dispatch: Dispatch<Action>) => {
//   return async ({ email, password }: SignInProps) => {
//     try {
//       const response = await serverApi.post('/signup', { email, password });
//       console.log(response.data);
//     } catch (err) {
//       console.log(err.message);
//     }
//   };
// };

// const signin = (dispatch: Dispatch<Action>) => {
//   return ({ email, password }: SignInProps) => { };
// };

// const signout = (dispatch: Dispatch<Action>) => {
//   return () => { };
// };

// export const { Provider, Context } = createDataContext(
//   authReducer,
//   { signin, signout, signup },
//   { isSignedIn: false },
// );
