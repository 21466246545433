import type { HTMLAttributes, RefObject } from 'react';
import React from 'react';

export interface IContainer {
  ref?: RefObject<HTMLDivElement>;
}

export type ContainerProps = HTMLAttributes<HTMLDivElement>;

const Container = React.forwardRef<HTMLDivElement, ContainerProps>(
  ({ children, ...props }, ref) => (
    <div {...props} ref={ref}>
      {children}
    </div>
  ),
);

Container.displayName = 'Container';
export default Container;
