import type { SVGProps } from 'react';
import React, { useState } from 'react';
import clsx from 'clsx';

import AspectRatioContainer from '@atoms/aspect-ratio-container/AspectRatioContainer';
import ButtonCta from '@atoms/ButtonCta.tsx';
import Icon from '@atoms/icon/Icon';
import IconPosterPlay from '@atoms/svg/v2/IconPosterPlay';

import { noop } from '@lib/helpers';

export interface IVideoPoster {
  img?: string;
  watermark?: React.FC<SVGProps<SVGSVGElement>>;
  primary?: boolean;
  header?: string;
  subheader?: string;
  className?: string;
  priority?: boolean;
  usePlaceholder?: boolean;
  explicit?: boolean;
  showExplicitWarning?: boolean;
  onClick?: (
    event: React.MouseEvent,
    dismissedExplicit: boolean,
    closedExplicitWarning?: boolean,
  ) => void;
  aspectRatio?: 'default' | string;
  maxWidth?: number;
}

const VideoPoster: React.FC<IVideoPoster> = ({
  img,
  primary = false,
  priority = false,
  header,
  subheader,
  watermark: WaterMark,
  className,
  onClick = noop,
  explicit,
  showExplicitWarning,
  aspectRatio,
  maxWidth,
}) => {
  const [ loaded, setLoaded ] = useState<boolean>(false);
  // const [hoverActive, setHoverActive] = useState<boolean>(false);

  return (
    <>
      {showExplicitWarning ? (
        <AspectRatioContainer
          aspectW={
            aspectRatio === 'default' ? 16 : parseFloat((aspectRatio || '16x9').split('x')[0])
          }
          aspectH={
            aspectRatio === 'default' ? 9 : parseFloat((aspectRatio || '16x9').split('x')[1])
          }
          maxWidth={maxWidth}
          id="ExplicitWarning"
          className={clsx('w-full relative group', 'overflow-hidden', className)}
          onClick={event => onClick(event, true)}
          // onMouseOver={() => setHoverActive(true)}
          // onMouseOut={() => setHoverActive(false)}
        >
          <div
            className={clsx(
              'w-full h-full bg-[#bfa48b]',
              'flex justify-center items-center flex-col',
            )}
          >
            <div className="flex justify-center items-center flex-col max-w-[275px] center">
              <div
                className="close-button absolute top-0 right-12px text-4xl z-50 font-avenir-light capitalize"
                onClick={event => onClick(event, false, true)}
              >
                ×
              </div>
              <p className="copy mb-10px leading-tight italic text-[15px] text-center">
                Heads up! This is a demonstration video, so it has explicit content. All of these
                videos have an &quot;explicit&quot; tag or are marked with this symbol:
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 82.84 71.48"
                className="explicit-icon h-30px w-30px"
              >
                <g id="Layer_2_copy" data-name="Layer 2">
                  <path
                    fill="#4e4e4e"
                    d="M54.5,31.88h0c-7.19,3.14-19.57,3.3-26.2,0h0a1.49,1.49,0,0,0-2.2,2L40,49.08V70a1.5,1.5,0,0,0,3,0V49.08l13.9-15.2a1.45,1.45,0,0,0-.1-2.1A1.69,1.69,0,0,0,54.5,31.88Z"
                  />
                  <path
                    fill="#4e4e4e"
                    d="M10.9.18a1.49,1.49,0,0,0-2,.6c-.2.3-18,32.9-2.7,69.8a1.42,1.42,0,0,0,1.4.9,1.27,1.27,0,0,0,.6-.1,1.51,1.51,0,0,0,.8-2c-14.7-35.5,2.4-66.9,2.6-67.2A1.67,1.67,0,0,0,10.9.18Z"
                  />
                  <path
                    fill="#4e4e4e"
                    d="M73.9.78a1.48,1.48,0,1,0-2.6,1.4c.2.3,17.3,31.6,2.6,67.2a1.54,1.54,0,0,0,.8,2,1.27,1.27,0,0,0,.6.1,1.51,1.51,0,0,0,1.4-.9C91.9,33.68,74.1,1.08,73.9.78Z"
                  />
                </g>
              </svg>
              <ButtonCta className="!w-auto px-16px mt-16px">
                <div onClick={event => onClick(event, true, false)}>Ok, let&apos;s go!</div>
              </ButtonCta>
            </div>
          </div>
        </AspectRatioContainer>
      ) : (
        <AspectRatioContainer
          aspectW={
            aspectRatio === 'default' ? 16 : parseFloat((aspectRatio || '16x9').split('x')[0])
          }
          aspectH={
            aspectRatio === 'default' ? 9 : parseFloat((aspectRatio || '16x9').split('x')[1])
          }
          maxWidth={maxWidth}
          id="VideoPoster"
          className={clsx('w-full relative group', 'overflow-hidden', className)}
          onClick={event => onClick(event, false)}
          // onMouseOver={() => setHoverActive(true)}
          // onMouseOut={() => setHoverActive(false)}
        >
          <div
            className={clsx(
              'content-full',
              'transition-transform duration-300 ease-out',
              // 'scale-100',
              'group-hover:scale-[1.03]',
            )}
          >
            {img && (
              <img
                src={img}
                // layout="fill"
                alt=""
                // priority={priority}
                // objectFit="cover"
                // objectPosition="center"
                // placeholder={usePlaceholder ? 'blur' : 'empty'}
                // blurDataURL={usePlaceholder ? HomeHeroImg : undefined}
                onLoad={() => setLoaded(true)}
              />
            )}
          </div>

          <div className={clsx('content-full', 'flex flex-col items-start justify-start')}>
            <div
              className={clsx(
                'w-full',
                'pt-12px pr-12px md:pt-16px md:pr-16px',
                'flex flex-row justify-end items-center',
              )}
            >
              {explicit && (
                <div
                  className={clsx(
                    'rounded bg-light-lemon w-[84px] h-[26px] lg:w-[96px] lg:h-[32px] flex flex-row items-center  justify-center gap-8px',
                  )}
                >
                  <div>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.03018 2.02888C1.89633 1.96531 1.73066 2.01011 1.65773 2.12962C1.62049 2.17998 -1.69427 7.65317 1.15493 13.8483C1.19656 13.9441 1.30168 14.005 1.41564 13.9994C1.45385 14.002 1.49219 13.9963 1.52738 13.9826C1.59697 13.9563 1.65194 13.906 1.67996 13.8429C1.70797 13.7797 1.70668 13.7091 1.67636 13.6468C-1.06111 7.68674 2.12329 2.41503 2.16053 2.36466C2.21149 2.23903 2.15691 2.09844 2.03018 2.02888Z"
                        fill="#292625"
                      />
                      <path
                        d="M10.5088 7.28538C9.11696 7.81753 6.72044 7.84465 5.43701 7.28538C5.32759 7.19333 5.15542 7.19125 5.04314 7.28061C4.93085 7.36998 4.91686 7.52023 5.01113 7.62433L7.70189 10.2004V13.7458C7.70189 13.8862 7.83189 14 7.99226 14C8.15263 14 8.28263 13.8862 8.28263 13.7458V10.2004L10.9734 7.62433C11.0266 7.57567 11.0546 7.51002 11.051 7.44275C11.0473 7.37547 11.0123 7.31245 10.954 7.26843C10.8235 7.17103 10.6292 7.17842 10.5088 7.28538Z"
                        fill="#292625"
                      />
                      <path
                        d="M14.3445 2.14583C14.3022 2.06134 14.2115 2.00523 14.1091 2.00035C14.0068 1.99547 13.91 2.04264 13.8582 2.12264C13.8063 2.20264 13.8081 2.30212 13.8628 2.38055C13.8999 2.43085 17.0678 7.6786 14.3445 13.6473C14.2849 13.7769 14.3508 13.9259 14.4927 13.9826C14.5277 13.9963 14.5658 14.002 14.6038 13.9994C14.7159 14.0006 14.8179 13.9412 14.8632 13.8485C17.6791 7.66184 14.3815 2.19613 14.3445 2.14583Z"
                        fill="#292625"
                      />
                    </svg>
                  </div>
                  <div
                    className={clsx(
                      'article-video-explicit-text drop-shadow',
                      'opacity-80 group-hover:opacity-100',
                    )}
                  >
                    Explicit
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className={clsx('content-full', 'flex flex-col items-start justify-end')}>
            <div
              className={clsx(
                'w-full',
                'bg-gradient-to-t from-black/[.70] to-transparent',
                'pb-12px pl-12px md:pb-16px md:pl-16px',
                'flex flex-row justify-between items-center',
              )}
            >
              <div
                className={clsx(
                  'flex flex-row items-center flex-shrink-0 flex-grow-0',
                  !loaded && !priority && 'hidden',
                )}
              >
                <Icon
                  transparent={true}
                  padded={false}
                  color={'white'}
                  icon={IconPosterPlay}
                  className={clsx('mr-16px drop-shadow', 'opacity-80 group-hover:opacity-100')}
                />

                <div className={clsx('text-white leading-20px')}>
                  {header && (
                    <>
                      {/* -- V2 Header -- */}
                      <div
                        className={clsx(
                          '!font-mabry-pro-bold drop-shadow',
                          'opacity-80 group-hover:opacity-100',
                          primary ? 'text-headline-5 lg:text-headline-4' : 'text-headline-4',
                        )}
                      >
                        {header}
                      </div>
                    </>
                  )}
                  {subheader && (
                    <>
                      {/* -- V1 Subheader -- */}
                      <div
                        className={clsx(
                          'omgyes-v2:hidden omgyes-v2:invisible',
                          '!text-xs md:!text-sm',
                          primary && '!text-sm md:!text-lg',
                        )}
                      >
                        {subheader}
                      </div>
                      {/* -- V2 Subheader -- */}
                      <div
                        className={clsx(
                          'omgyes-v1:hidden omgyes-v1:invisible',
                          'opacity-80 group-hover:opacity-100',
                          primary ? 'text-body-4 lg:text-body-3' : 'text-body-4',
                        )}
                      >
                        {subheader}
                      </div>
                    </>
                  )}
                </div>
              </div>

              {WaterMark && (
                <WaterMark
                  className={clsx(
                    'fill-ui-sugar h-20px pr-12px md:pr-16px',
                    'opacity-80 group-hover:opacity-100',
                    // 'h-full w-full absolute top-0 right-0',
                  )}
                  preserveAspectRatio="xMinYMin meet"
                />
              )}
            </div>
          </div>
        </AspectRatioContainer>
      )}
    </>
  );
};

export default VideoPoster;
