import type { SVGProps } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import type FilePlayer from 'react-player/file';
import clsx from 'clsx';

import AspectRatioContainer from '@atoms/aspect-ratio-container/AspectRatioContainer';
import VideoDisplayHtml5 from '@atoms/video-display-html5/VideoDisplayHtml5';
import VideoPoster from '@atoms/video-poster/VideoPoster';
import type { Video } from '@components/types';
import VideoControls from '@components/video/VideoControls.tsx';

import { useVideoContext } from '@lib/contexts/VideoContext';
import useAnalytics from '@lib/hooks/useAnalytics';
import { AnalyticsEvent } from '@root/constants';

export interface IVideoPlayer extends Video {
  className?: string;
  aspectRatio?: 'default' | string;
  maxWidth?: number;
  primary?: boolean;
  autoPlay?: boolean;
  unmuteFlag?: boolean;
  muted?: boolean;
  active?: boolean;
  controls?: boolean;
  loop?: boolean;
  // light?: boolean,
  explicit?: boolean;
  track?: boolean;
  placeholder?: boolean;
  priority?: boolean;
  watermark?: React.FC<SVGProps<SVGSVGElement>>;
  videoDisplayStyle?: React.CSSProperties;
  fullFrameControls?: boolean;
}

const VideoPlayer: React.FC<IVideoPlayer> = ({
  id,
  className,
  maxWidth,
  url,
  poster,
  header,
  subheader,
  controls: PropControls = true,
  watermark,
  subtitles = [],
  aspectRatio = 'default',
  type = 'auto',
  loop = false,
  unmuteFlag = false,
  track = true,
  autoPlay: PropAutoPlay = false,
  placeholder = false,
  // light = true,
  explicit,
  primary = false,
  priority = false,
  active: PropActive = false,
  muted: PropMuted = false,
  videoDisplayStyle,
  fullFrameControls = false,
}) => {
  const {
    state: { videoId },
    setVideo,
  } = useVideoContext();
  const fileRef = useRef<FilePlayer>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [ active, setActive ] = useState<boolean>(PropActive);
  const [ autoPlay, setAutoPlay ] = useState<boolean>(false);
  const [ controls, setControls ] = useState<boolean | undefined>();
  const [ playedOnce, setPlayedOnce ] = useState<boolean>(false);
  const [ playing, setPlaying ] = useState<boolean>(false);
  const [ seeking ] = useState<boolean>(false);
  const [ muted, setMuted ] = useState<boolean>(false);
  const [ volume ] = useState<number>(1);
  const [ duration, setDuration ] = useState<number | undefined>();
  const [ progress, setProgress ] = useState<number | undefined>();
  const { analytics } = useAnalytics();
  const [ thresholdsReached, setThresholdsReached ] = useState({
    tenPercent: false,
    fiftyPercent: false,
    ninetyPercent: false,
  });
  const [ showExplicitWarning, setShowExplicitWarning ] = useState<boolean>(false);
  const [ _cookies, setCookie ] = useCookies([ '_exM' ]);
  const [ subtitlesEnabled, setSubtitlesEnabled ] = useState<boolean>(false);

  useEffect(() => setPlaying(active), [ active ]);
  useEffect(() => setAutoPlay(PropAutoPlay), [ PropAutoPlay ]);
  useEffect(() => setActive(PropActive), [ PropActive ]);
  useEffect(() => setMuted(PropMuted), [ PropMuted ]);
  useEffect(() => {
    if (typeof PropControls !== 'undefined') setControls(PropControls);
  }, [ PropControls ]);
  useEffect(() => {
    const handleStorageChange = () => {
      const preferredLanguage = localStorage.getItem('ccl');
      const matchingTrack = subtitles.find(subtitle => subtitle.srcLang === preferredLanguage);
      if (matchingTrack) {
        setSubtitlesEnabled(true);
      } else {
        setSubtitlesEnabled(false);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    // Initial check
    handleStorageChange();

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [ subtitles ]);

  useEffect(() => {
    if (videoId !== id && active) {
      setActive(false);
      setAutoPlay(false);
    }
  }, [ videoId, active, id ]);

  useEffect(() => {
    if (autoPlay === true && !playing && !playedOnce) {
      setControls(false);
      setMuted(true);
      setPlaying(true);
      setActive(true);
      setVideo(id);
    }
  }, [
    autoPlay, id, setVideo, playing, playedOnce, 
  ]);

  if (type === 'auto') {
    type = url?.match(/vimeo\.com/g) ? 'vimeo' : url?.match(/\.m3u8$/) ? 'hls' : 'file';
  }

  const handleSeek = (val: number) => {
    if (id && track) {
      analytics.track({
        event: AnalyticsEvent.VIDEO_CLICK,
        props: { seek: val, Video_source: url, label: id },
      });
    }
  };

  const handlePosterClick = (
    event: React.MouseEvent,
    dismissedExplicit: boolean,
    closedExplicitWarning?: boolean,
  ) => {
    event.stopPropagation(); // stopping propagation since there are nested click handlers on the explicit overlay

    const shouldShowWarning = explicit && !dismissedExplicit && !_cookies._exM;
    const shouldHideWarning = explicit && !dismissedExplicit && closedExplicitWarning;
    const shouldSetCookie = explicit && dismissedExplicit && !_cookies._exM;

    if (shouldHideWarning) {
      setShowExplicitWarning(false);
      return;
    }

    if (shouldShowWarning) {
      setShowExplicitWarning(true);
      return;
    }

    if (shouldSetCookie) {
      setCookie('_exM', true, { domain: '.omgyes.com' });
    }

    setPlaying(true);
    setActive(true);
    setVideo(id);

    if (id && track) {
      analytics.track({
        event: AnalyticsEvent.VIDEO_CLICK,
        props: { Video_source: url, label: id },
      });
    }
  };

  // useEffect(() => {
  //   if (progress && id && track) {
  //     analytics.track({
  //       event: AnalyticsEvent.VIDEO_TRACK,
  //       props: { progress: progress, duration: duration, Video_source: id, label: id },
  //     });
  //   }
  // }, [progress, duration]);

  useEffect(() => {
    if (progress !== undefined && duration !== undefined && id && track) {
      const progressPercent = (progress / duration) * 100;

      if (!thresholdsReached.tenPercent && progressPercent >= 10) {
        analytics.track({
          event: AnalyticsEvent.VIDEO_TRACK,
          props: { threshold: '10', progress, duration, Video_source: url, label: id },
        });
        setThresholdsReached(prev => ({ ...prev, tenPercent: true }));
      } else if (!thresholdsReached.fiftyPercent && progressPercent >= 50) {
        analytics.track({
          event: AnalyticsEvent.VIDEO_TRACK,
          props: { threshold: '50', progress, duration, Video_source: url, label: id },
        });
        setThresholdsReached(prev => ({ ...prev, fiftyPercent: true }));
      } else if (!thresholdsReached.ninetyPercent && progressPercent >= 90) {
        analytics.track({
          event: AnalyticsEvent.VIDEO_TRACK,
          props: { threshold: '90', progress, duration, Video_source: url, label: id },
        });
        setThresholdsReached(prev => ({ ...prev, ninetyPercent: true }));
      }
    }
  }, [
    progress, duration, id, track, thresholdsReached, 
  ]);

  const handleVideoEnd = () => {
    setPlayedOnce(true);
    handleSeek(0);

    if (!loop) {
      setVideo(null);
      setActive(false);
    }
  };

  const handleMuteChange = (val: boolean) => {
    if (val === false && id && track) {
      analytics.track({
        event: AnalyticsEvent.VIDEO_UNMUTE,
        props: { Video_source: url, label: id },
      });
    }

    if (val === false) {
      setControls(true);
    }

    setMuted(val);
  };

  const handleSubtitleChange = (textTracks: TextTrackList) => {
    let subtitleEnabled = false;
    for (let i = 0; i < textTracks.length; i++) {
      if (textTracks[i].mode === 'showing') {
        setSubtitlesEnabled(true);
        localStorage.setItem('ccl', textTracks[i].language);
        subtitleEnabled = true;
        break;
      }
    }
    if (!subtitleEnabled) {
      setSubtitlesEnabled(false);
      localStorage.removeItem('ccl');
    }
  };

  return (
    <AspectRatioContainer
      aspectW={aspectRatio === 'default' ? 16 : parseFloat((aspectRatio || '16x9').split('x')[0])}
      aspectH={aspectRatio === 'default' ? 9 : parseFloat((aspectRatio || '16x9').split('x')[1])}
      maxWidth={maxWidth}
      className={clsx([ 'relative', className ])}
      containerRef={containerRef}
    >
      <div>
        {/* -- Layer 0: Video Display -- */}
        <VideoDisplayHtml5
          id={id}
          playerRef={fileRef}
          className={clsx('content-full')}
          url={url}
          subtitles={subtitles}
          muted={muted}
          loop={loop}
          playing={playing && !seeking}
          subtitlesEnabled={subtitlesEnabled}
          seeking={seeking}
          volume={volume}
          light={false}
          controls={controls}
          onClick={() => muted && setMuted(false)}
          onMuteToggle={handleMuteChange}
          onProgress={({ playedSeconds }: { playedSeconds: number }) => setProgress(playedSeconds)}
          onDuration={(val: number) => setDuration(val)}
          onEnded={handleVideoEnd}
          style={videoDisplayStyle}
          onSubtitleChange={handleSubtitleChange}
        />

        {unmuteFlag && muted ? (
          <div
            className={clsx(
              'absolute top-8px left-8px sm:top-16px sm:left-16px',
              '!w-auto !h-auto',
              'bg-ui-pepper bg-opacity-90 rounded-lg',
              'py-8px px-16px',
            )}
            onClick={() => {
              if (muted) setMuted(false);
              setControls(true);
            }}
          >
            <div className={clsx('text-headline-4 sm:text-title-4', 'text-ui-sugar ')}>
              Tap to Unmute
            </div>
          </div>
        ) : null}
      </div>

      {/* -- Layer 1: Full Frame Video controls (includes a
                        full frame pause button) -- Vimeo only*/}
      {fullFrameControls && (
        <div className={clsx('flex flex-col h-full max-h-full cursor-pointer')}>
          <VideoControls
            className={clsx('flex-grow')}
            playing={playing && !seeking}
            duration={duration}
            muted={muted}
            time={progress}
            volume={volume}
            seeking={seeking}
            // fullscreen={fullscreen}
            autoHide={false}
            onPlayChange={(val: boolean) => setPlaying(val)}
          />
        </div>
      )}

      {/* -- Layer 3: Poster Container --  */}
      {!fullFrameControls && (
        <VideoPoster
          className={clsx('z-[5] cursor-pointer', active && 'hidden')}
          aspectRatio={aspectRatio}
          maxWidth={maxWidth}
          img={poster}
          priority={priority}
          usePlaceholder={placeholder}
          primary={primary}
          header={header}
          subheader={subheader}
          onClick={handlePosterClick}
          watermark={watermark}
          explicit={explicit}
          showExplicitWarning={showExplicitWarning}
        />
      )}
    </AspectRatioContainer>
  );
};

export default VideoPlayer;
