import React, { forwardRef } from 'react';
import clsx from 'clsx';

import { cn } from '@lib/utils';

import FormLabel from './FormLabel';
import InputError from './InputError';

interface FormRadioInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  isError?: boolean;
}

export const FormRadioInput = forwardRef<HTMLInputElement, FormRadioInputProps>(
  ({ className, isError, ...props }, ref) => (
    <div className="relative inline-block w-6 h-6">
      <input
        type="radio"
        ref={ref}
        className={cn(
          'peer appearance-none absolute inset-0 w-full h-full rounded-full',
          'border-2 border-[#D9D7D6] ',
          'checked:border-[#D8591C] checked:bg-[#D8591C]',
          'focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-2',
          isError && 'border-red-500',
          className,
        )}
        {...props}
      />
      <svg
        className="absolute inset-0 w-full h-full pointer-events-none opacity-0 peer-checked:opacity-100"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.75 12.75L10.25 15.25L16.25 8.75"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  ),
);

FormRadioInput.displayName = 'FormRadioInput';

export interface FormRadioProps<T> extends React.InputHTMLAttributes<T> {
  error?: { message?: string };
  defaultErrorMessage?: string;
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  optionClassName?: string;
  optionSelectedClassName?: string;
  optionUnselectedClassName?: string;
  errorClassName?: string;
  register: () => Record<string, any>;
  name?: string;
  label?: string;
  options: any[];
}

export const FormRadio = ({
  error,
  defaultErrorMessage,
  className,
  labelClassName,
  inputClassName,
  errorClassName,
  optionClassName,
  optionSelectedClassName,
  optionUnselectedClassName,
  register,
  options,
  value,
  onChange,
  ...props
}: FormRadioProps<any>) => (
  <div className={className || 'w-full'}>
    {props.label && (
      <FormLabel className={labelClassName} htmlFor={props.name}>
        {props.label}
      </FormLabel>
    )}
    <fieldset className={inputClassName} onChange={onChange}>
      {options.map((option, index) => {
        return (
          <label className="w-full" key={`${index}-${option.value}`}>
            {option.boost && (
              <div id="boost" className="gift-page-boost">
                {option.boost}
              </div>
            )}
            <div
              className={clsx(
                optionClassName,
                option.value === value ? optionSelectedClassName : optionUnselectedClassName,
                'flex flex-row justify-between items-center',
              )}
            >
              {option.children}
              <FormRadioInput
                type="radio"
                className={inputClassName}
                isError={!!error}
                aria-invalid={!!error}
                {...props}
                {...register()}
                id={option.value}
                value={option.value}
              />
            </div>
          </label>
        );
      })}
    </fieldset>
    <InputError className={errorClassName} hidden={!(error?.message || defaultErrorMessage)}>
      {error?.message || defaultErrorMessage || ''}
    </InputError>
  </div>
);

export default FormRadio;
