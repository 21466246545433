import React from 'react';

const ProgressBar: React.FC = () => {
  return (
    <div className="relative h-2 w-full max-w-[300px] rounded-2xl bg-[#d9d9d9] overflow-hidden">
      <div className="absolute top-0 left-0 h-full w-1/2 rounded-2xl bg-[#2973db] animate-loading" />
    </div>
  );
};

export default ProgressBar;
