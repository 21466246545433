import type { Dispatch } from 'react';
import { useContext } from 'react';
import { Country } from '@data/countries';

import { Storage } from '@root/constants';

import type { Action } from './createDataContext';
import { createDataContext } from './createDataContext';

export enum AppTypes {
  INIT_STORED = 'init_stored',

  SET_COUNTRY = 'set_country',

  SET_DISCOUNT = 'set_discount',

  SET_DISCOUNT_QUERY = 'set_discount_query',

  SET_DISCOUNT_NAME = 'set_discount_name',

  SET_VARIANT = 'set_variant',

  SET_EXPERIMENT = 'set_experiment',

  SET_BANNER_ACTIVE = 'set_banner_active',

  SET_HOLIDAY = 'set_holiday',

  SET_PROMO = 'set_promo',

  SET_DEBUG_MODULES = 'set_debug_modules',
}

type Discount = {
  amount: number;
  name?: string;
  query?: Record<string, string | number>;
  product?: string;
};

type Experiment = {
  id: string;
  name?: string;
  variant?: number;
  variant_name?: string;
};

type AppState = {
  country: string;
  discount: Discount | null;
  bannerActive: boolean;
  experiment: Experiment | null;
  promo: string | null;
  debugModules: boolean;
};

type AppActions = {
  /**
   * Reducer Helpers
   */

  setCountry: (d: Dispatch<Action>) => (c: string) => void;

  setDiscount: (d: Dispatch<Action>) => (d: Discount) => void;

  setPromo: (d: Dispatch<Action>) => (p: string, b?: boolean) => void;

  setExperiment: (d: Dispatch<Action>) => (e: Experiment) => void;

  setBannerActive: (d: Dispatch<Action>) => (a: boolean) => void;

  setDebugModules: (d: Dispatch<Action>) => (d: boolean) => void;
};

interface IAppContext {
  country?: string;
  promo?: string;
  experiment?: Experiment;
  bannerActive?: boolean;
  debugModules?: boolean;
}

const initialState: AppState = {
  country: Country.US,
  bannerActive: false,
  discount: null,
  experiment: null,
  promo: null,
  debugModules: false,
};

const AppReducer = (state: AppState, action: Action): AppState => {
  switch (action.type) {
    case AppTypes.INIT_STORED:
    case AppTypes.SET_COUNTRY:
    case AppTypes.SET_DISCOUNT:
    case AppTypes.SET_EXPERIMENT:
    case AppTypes.SET_BANNER_ACTIVE:
    case AppTypes.SET_PROMO:
    case AppTypes.SET_DEBUG_MODULES:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

/**
 *
 */
const setDiscount = (dispatch: Dispatch<Action>) => (discount: Discount) => {
  dispatch({ type: AppTypes.SET_DISCOUNT, payload: { discount }});
};

/**
 *
 */
const setPromo = (dispatch: Dispatch<Action>) => (promo: string, bannerActive?: boolean) => {
  dispatch({ type: AppTypes.SET_PROMO, payload: { promo, bannerActive }});
};

/**
 *
 */
const setCountry = (dispatch: Dispatch<Action>) => (country: string) => {
  dispatch({ type: AppTypes.SET_COUNTRY, payload: { country }});
};

/**
 *
 */
const setExperiment = (dispatch: Dispatch<Action>) => (experiment: Experiment) => {
  dispatch({ type: AppTypes.SET_EXPERIMENT, payload: { experiment }});
};

/**
 *
 */
const setBannerActive = (dispatch: Dispatch<Action>) => (bannerActive: boolean) => {
  dispatch({ type: AppTypes.SET_BANNER_ACTIVE, payload: { bannerActive }});
};

/**
 *
 */
const setDebugModules = (dispatch: Dispatch<Action>) => (debugModules: boolean) => {
  dispatch({ type: AppTypes.SET_DEBUG_MODULES, payload: { debugModules }});
};

export const { Provider, Context } = createDataContext<AppActions, AppState, IAppContext>(
  AppReducer,
  { setPromo, setCountry, setExperiment, setBannerActive, setDiscount, setDebugModules },
  initialState,
  {
    persist: true,
    persist_key: Storage.APP,
    persist_ignore_keys: [ 'promo', 'bannerActive', 'debugModules' ],
  },
);

export const useAppContext = () => useContext(Context);
