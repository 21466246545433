import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import type { DateNightData, NewMemberHomeData } from '@types.ts';
import _ from 'lodash';

import { ArticleCarousel } from '@components/ArticleCarousel';
import TooltipModal from '@components/TooltipModal';

import { useContentful } from '@lib/contexts/ContentfulContext';

import { NMH_LOOKUP } from '@/config';

const THUMBNAIL_DIMENSION = 500;
export const DateNightSelector = () => {
  const navigate = useNavigate();
  const [ content, setContent ] = useState<DateNightData[]>([]);
  const [ tooltipText, setTooltipText ] = useState<string | null>(null);
  const [ hover, setHover ] = useState<number | undefined>(undefined);
  const { getEntry } = useContentful();

  useEffect(() => {
    getEntry(NMH_LOOKUP['en'], {
      include: 3,
    }).then((data: NewMemberHomeData) => {
      setContent(data?.fields.dateNights);
      setTooltipText(data.fields.dateNightTooltip);
    });
  });

  if (content?.length === 0) return <></>;

  return (
    <div className={'nmh-selector-container'}>
      <div className={'article-x-padding'}>
        <div className={'article-selector-headline'}>
          <div>Date night</div>
          <TooltipModal text={tooltipText || ''}>
            <div className={'flex items-center mt-4px'}>
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10 17.8237C14.1421 17.8237 17.5 14.4659 17.5 10.3237C17.5 6.18159 14.1421 2.82373 10 2.82373C5.85786 2.82373 2.5 6.18159 2.5 10.3237C2.5 14.4659 5.85786 17.8237 10 17.8237ZM10 19.0737C14.8325 19.0737 18.75 15.1562 18.75 10.3237C18.75 5.49124 14.8325 1.57373 10 1.57373C5.16751 1.57373 1.25 5.49124 1.25 10.3237C1.25 15.1562 5.16751 19.0737 10 19.0737Z"
                  fill="#292625"
                />
                <path
                  d="M8.75 6.57373C8.75 5.88337 9.30964 5.32373 10 5.32373C10.6904 5.32373 11.25 5.88337 11.25 6.57373C11.25 7.26409 10.6904 7.82373 10 7.82373C9.30964 7.82373 8.75 7.26409 8.75 6.57373Z"
                  fill="#292625"
                />
                <path
                  d="M10 9.07373C9.48223 9.07373 9.0625 9.49346 9.0625 10.0112V14.3862C9.0625 14.904 9.48223 15.3237 10 15.3237C10.5178 15.3237 10.9375 14.904 10.9375 14.3862V10.0112C10.9375 9.49346 10.5178 9.07373 10 9.07373Z"
                  fill="#292625"
                />
              </svg>
            </div>
          </TooltipModal>
        </div>
      </div>
      <ArticleCarousel
        showing={{
          xs: 3.2,
          sm: 3.2,
          md: 4,
          lg: 4,
        }}
      >
        {content.map(({ fields }, index) => {
          return (
            <div key={index} className={'article-selector-tile-box hover:cursor-pointer'}>
              <div
                className={'date-night-selector-tile'}
                onClick={() => navigate(`/date-night/${fields.slug || _.kebabCase(fields.title)}`)}
                onMouseEnter={() => setHover(index)}
                onMouseLeave={() => setHover(undefined)}
                style={{
                  background:
                    hover === index
                      ? `linear-gradient(to top, rgba(195, 85, 40, 0.9), rgba(195, 85, 40, 0) 50%), linear-gradient(0deg, rgba(254, 239, 173, 0.05) 0%, rgba(254, 239, 173, 0.05) 100%), url(${fields.image.fields.file.url}?w=${THUMBNAIL_DIMENSION}&h=${THUMBNAIL_DIMENSION}) left bottom / cover`
                      : `linear-gradient(to top, rgba(195, 85, 40, 1), rgba(195, 85, 40, 0) 50%), url(${fields.image.fields.file.url}?w=${THUMBNAIL_DIMENSION}&h=${THUMBNAIL_DIMENSION}) left bottom / cover`,
                  textShadow: '0px 1px 0px #302627',
                }}
              >
                <div className={'date-night-selector-tile-content'}>{fields.title}</div>
              </div>
            </div>
          );
        })}
      </ArticleCarousel>
    </div>
  );
};
