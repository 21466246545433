import type { ReactNode } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import useEmblaCarousel from 'embla-carousel-react';

import { getScreenWidth } from '@lib/screen.ts';

import '../styles/components/giftPageCarousel.css';

export const GiftPageCarousel = ({
  children,
  scrollIconTopMargin,
  dark,
  onSelect,
}: {
  children: ReactNode[];
  showing: { xs: number; sm: number; md: number; lg: number };
  scrollIconTopMargin?: string;
  dark?: boolean;
  onSelect: (v: number) => void;
}) => {
  const [ currentItem, setCurrentItem ] = useState<number>(0);
  const refs = useRef<(null | HTMLDivElement)[]>([]);
  const [ width, setWidth ] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    scrollItThere(currentItem);
  }, [ width ]);

  useEffect(() => {
    const move = async () => {
      currentItem > -1 && (await scrollItThere(currentItem));
    };
    move();
    onSelect(currentItem);
  }, [ currentItem ]);

  //////////////////////////
  const [ emblaRef, emblaApi ] = useEmblaCarousel({
    loop: true,
    align: 'center',
    slidesToScroll: 1,
  });

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [ emblaApi ]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [ emblaApi ]);

  // Handle keyboard navigation
  useEffect(() => {
    const handleKeyDown = (e: { key: string }) => {
      if (e.key === 'ArrowLeft') scrollPrev();
      if (e.key === 'ArrowRight') scrollNext();
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [ scrollPrev, scrollNext ]);

  useEffect(() => {
    if (emblaApi) {
      const onSelect = () => {
        const selectedIndex = emblaApi.selectedScrollSnap();
        setCurrentItem(selectedIndex); // Update the currentItem to the selected index
      };

      emblaApi.on('select', onSelect); // Listen for selection changes
      onSelect(); // Trigger onSelect initially to set the correct item

      return () => {
        emblaApi.off('select', onSelect); // Cleanup listener on unmount
      };
    }
  }, [ emblaApi ]);

  const scrollItThere = async (atPage: number) => {
    const effectiveIndex = atPage % children.length;
    refs.current[effectiveIndex]?.scrollIntoView({
      block: 'nearest',
      inline: 'center',
      behavior: 'smooth',
    });
  };

  const stepItem = (step: number) => {
    setCurrentItem(v => {
      const nextItem = v + step;
      return nextItem < 0 ? children.length - 1 : nextItem % children.length;
    });
  };

  if (width < getScreenWidth('sm')) {
    return (
      <div className={'flex flex-col w-full justify-center gap-16px'}>
        <div className={'gift-carousel-tile-overflow'}>
          <div className={'gift-carousel-tile-container'}>
            {children.map((child, index) => (
              <div
                key={index}
                ref={i => (refs.current[index] = i)}
                className={clsx('gift-carousel-tile', 'h-full', 'w-[calc(100vw-48px)]')}
              >
                <div className={currentItem === index ? 'gift-page-card-image-selected' : ''}>
                  {child}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={'w-full justify-between flex flex-row items-center'}>
          <div
            className={clsx(
              'article-selector-tile-scroll-arrow-wrapper',
              'hover:cursor-pointer',
              scrollIconTopMargin || '',
              dark ? 'bg-white bg-opacity-5 text-ui-sugar' : 'bg-ui-garlic',
            )}
            onClick={() => stepItem(-1)}
          >
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.2361 16.027C13.5046 15.7693 13.5046 15.3516 13.2361 15.094L8.22227 10.2827L13.2361 5.47143C13.5046 5.21379 13.5046 4.79608 13.2361 4.53844C12.9677 4.28081 12.5323 4.28081 12.2639 4.53844L6.76386 9.81622C6.49538 10.0739 6.49538 10.4916 6.76386 10.7492L12.2639 16.027C12.5324 16.2846 12.9677 16.2846 13.2361 16.027Z"
                fill="currentColor"
              />
            </svg>
          </div>
          <div className={'flex flex-row gap-8px'}>
            {children.map((_item, index) => {
              return (
                <>
                  {currentItem === index ? (
                    <svg
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="4" cy="4" r="4" fill="#D8591C" />
                    </svg>
                  ) : (
                    <svg
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="4" cy="4" r="4" fill="#D9D7D6" />
                    </svg>
                  )}
                </>
              );
            })}
          </div>
          <div
            className={clsx(
              'article-selector-tile-scroll-arrow-wrapper',
              'hover:cursor-pointer',
              scrollIconTopMargin || '',
              dark ? 'bg-white bg-opacity-5 text-ui-sugar' : 'bg-ui-garlic',
            )}
            onClick={() => stepItem(1)}
          >
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.01386 4.53844C7.74538 4.79608 7.74538 5.21379 8.01386 5.47143L13.0277 10.2827L8.01386 15.094C7.74538 15.3516 7.74538 15.7693 8.01386 16.027C8.28235 16.2846 8.71765 16.2846 8.98614 16.027L14.4861 10.7492C14.7546 10.4916 14.7546 10.0739 14.4861 9.81622L8.98614 4.53844C8.71765 4.28081 8.28235 4.28081 8.01386 4.53844Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  } else {
    // not xs screen.
    return (
      <div className={'flex flex-row gap-8px'}>
        {/* Navigation Buttons */}
        <div className={'w-40px flex-none'}>
          <div className={'w-[40px] flex h-full justify-center items-center'}>
            <div
              className={clsx(
                'article-selector-tile-scroll-arrow-wrapper',
                'hover:cursor-pointer',
                scrollIconTopMargin || '',
                dark ? 'bg-white bg-opacity-5 text-ui-sugar' : 'bg-ui-garlic',
              )}
              onClick={() => scrollPrev()}
            >
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.2361 16.027C13.5046 15.7693 13.5046 15.3516 13.2361 15.094L8.22227 10.2827L13.2361 5.47143C13.5046 5.21379 13.5046 4.79608 13.2361 4.53844C12.9677 4.28081 12.5323 4.28081 12.2639 4.53844L6.76386 9.81622C6.49538 10.0739 6.49538 10.4916 6.76386 10.7492L12.2639 16.027C12.5324 16.2846 12.9677 16.2846 13.2361 16.027Z"
                  fill="currentColor"
                />
              </svg>
            </div>
          </div>
        </div>

        {/* Carousel Viewport */}
        <div className={'flex justify-center relative '}>
          <div className={'gift-page-card-image-prev'} />
          <div className={'gift-carousel-tile-overflow '}>
            <div className={'gift-carousel-tile-container'}>
              <div className="overflow-hidden" ref={emblaRef}>
                {/* Carousel Container */}
                <div className="flex touch-pan-y">
                  {/* Slides */}
                  {[ ...children, ...children ].map((child, index) => (
                    <div
                      key={index}
                      className="flex-[0_0_100%] min-w-0 max-w-[414px] mx-[8px] relative"
                    >
                      <div
                        className={clsx(
                          'gift-carousel-tile',
                          'h-full',
                          'sm:w-[414px]',
                          'transform transition-all duration-300',
                        )}
                      >
                        <>
                          <div
                            className={clsx(
                              'relative',
                              currentItem === index ? 'gift-page-card-image-selected' : '',
                            )}
                          >
                            {child}
                          </div>
                        </>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={'gift-page-card-image-next'} />
        </div>

        <div className={'w-40px flex-none'}>
          <div className={'w-[40px] flex h-full justify-center items-center'}>
            <div
              className={clsx(
                'article-selector-tile-scroll-arrow-wrapper',
                'hover:cursor-pointer',
                scrollIconTopMargin || '',
                dark ? 'bg-white bg-opacity-5 text-ui-sugar' : 'bg-ui-garlic',
              )}
              onClick={() => scrollNext()}
            >
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.01386 4.53844C7.74538 4.79608 7.74538 5.21379 8.01386 5.47143L13.0277 10.2827L8.01386 15.094C7.74538 15.3516 7.74538 15.7693 8.01386 16.027C8.28235 16.2846 8.71765 16.2846 8.98614 16.027L14.4861 10.7492C14.7546 10.4916 14.7546 10.0739 14.4861 9.81622L8.98614 4.53844C8.71765 4.28081 8.28235 4.28081 8.01386 4.53844Z"
                  fill="currentColor"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
