import React, { useCallback, useState } from 'react';
import { TfiReload, TfiSave } from 'react-icons/tfi';
import { CircleLoader } from 'react-spinners';
import { Allotment } from 'allotment';
import _ from 'lodash';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { doQuery } from '@lib/query.tsx';
import LocalizationsList from '@pages/admin/ProductCatalog/LocalizationsList.tsx';
import PricingsList from '@pages/admin/ProductCatalog/PricingsList.tsx';
import {
  CREATE_PRODUCT_MUTATION,
  UPDATE_PRODUCT_MUTATION,
} from '@pages/admin/ProductCatalog/productsGql.ts';
import {
  editEntitlementsState,
  editProductLocalizationsState,
  editProductPricingsState,
  productDetails,
  productSummaries,
  resetEdits,
  selectedProductState,
} from '@pages/admin/ProductCatalog/store.ts';
import { keep } from '@pages/utils.ts';

import { Button } from './components/Button.tsx';
import { FullPageDiv } from './components/FullPageDiv.tsx';
import { Toolbar } from './components/Toolbar.tsx';
import EntitlementsList from './EntitlementsList.tsx';
import ProductsList from './ProductsList.tsx';

import 'allotment/dist/style.css';

const Fallback = (
  <FullPageDiv overlay={true} justify={'center'}>
    <CircleLoader />
  </FullPageDiv>
);

const PRICING_FIELDS = [
  'amount',
  'strikeThrough',
  'currency',
  'countryCodes',
  'startDate',
  'endDate',
  'version',
];
const LOCALIZATION_FIELDS = [
  'name', 'description', 'languageCode', 'version', 
];

const ProductEditor = () => {
  const [ isSaving, setIsSaving ] = useState(false);

  const [ productId, setProductId ] = useRecoilState(selectedProductState);
  const [ product, setProduct ] = useRecoilState(productDetails(productId));
  const setProducts = useSetRecoilState(productSummaries);
  const setResetEditsTrigger = useSetRecoilState(resetEdits);

  const editedLocalizations = useRecoilValue(editProductLocalizationsState);
  const editedPricings = useRecoilValue(editProductPricingsState);
  const selectedEntitlements = useRecoilValue(editEntitlementsState);

  const saveProduct = useCallback(async () => {
    setIsSaving(true);
    try {
      const setEntitlements = selectedEntitlements.map(name => ({ name }));

      const createPricings = editedPricings.filter(({ id, originalId }) => id && !originalId);
      const updatePricings = editedPricings.filter(
        ({ originalId, deleted }) => !!originalId && !deleted,
      );
      const deletedPricings = editedPricings.filter(({ deleted }) => deleted);

      const pricings = {
        create: keep(createPricings, PRICING_FIELDS),
        update: updatePricings.map(({ id, ...rest }) => ({
          where: { id },
          data: _.pick(rest, PRICING_FIELDS),
        })),
        delete: keep(deletedPricings, 'id'),
      };

      const createLocalizations = editedLocalizations.filter(
        ({ id, originalId }) => id && !originalId,
      );
      const updateLocalizations = editedLocalizations.filter(
        ({ originalId, deleted }) => !!originalId && !deleted,
      );
      const deletedLocalizations = editedLocalizations.filter(({ deleted }) => deleted);

      const localizations = {
        create: keep(createLocalizations, LOCALIZATION_FIELDS),
        update: updateLocalizations.map(({ id, ...rest }) => ({
          where: { id },
          data: _.pick(rest, LOCALIZATION_FIELDS),
        })),
        delete: keep(deletedLocalizations, 'id'),
      };

      const updatedProduct = product
        ? await doQuery({
          query: UPDATE_PRODUCT_MUTATION,
          variables: {
            id: productId,
            entitlements: setEntitlements,
            pricings,
            localizations,
          },
          transformer: 'updateProduct',
        })
        : await doQuery({
          query: CREATE_PRODUCT_MUTATION,
          variables: {
            id: productId,
            entitlements: setEntitlements,
            pricings: keep(createPricings, PRICING_FIELDS),
            localizations: keep(createLocalizations, LOCALIZATION_FIELDS),
          },
          transformer: 'createProduct',
        });

      setProduct(updatedProduct);
      setProducts(products => {
        const index =
          products.findIndex(({ id }) => id === updatedProduct?.id) + 1 ||
          products.findIndex(({ id }) => id === productId) + 1 ||
          products.length + 1;

        return [ ...products.slice(0, index - 1), updatedProduct, ...products.slice(index) ];
      });
      if (productId !== updatedProduct?.id) {
        setProductId(updatedProduct?.id);
      }
    } finally {
      setIsSaving(false);
    }
  }, [
    productId, product, selectedEntitlements, editedLocalizations, editedPricings, 
  ]);

  if (!productId) {
    return <FullPageDiv justify={'center'}>Select a product to edit.</FullPageDiv>;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {isSaving && Fallback}
      <Toolbar>
        <Button onClick={() => setResetEditsTrigger(Math.random())}>
          <TfiReload />
        </Button>
        <Button onClick={saveProduct}>
          <TfiSave />
        </Button>
      </Toolbar>
      <Allotment vertical={true}>
        <Allotment defaultSizes={[ 20, 80 ]}>
          <EntitlementsList />
          <PricingsList />
        </Allotment>
        <LocalizationsList />
      </Allotment>
    </div>
  );
};

export default function ProductCatalog() {
  return (
    <FullPageDiv>
      <Allotment defaultSizes={[ 22, 78 ]}>
        <ProductsList />
        <React.Suspense fallback={Fallback}>
          <ProductEditor />
        </React.Suspense>
      </Allotment>
    </FullPageDiv>
  );
}
