export const screens: { [key: string]: string } = {
  xs: '360px',
  ss: '456px',
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  '2xl': '1440px',
  '3xl': '1920px',
};
