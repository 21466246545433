import PageFooter from '@components/common/PageFooter';

const GiftPageFooter = () => {
  return (
    <div className="gift-page-footer">
      <PageFooter bgColor="bg-sugar" textColor="text-ui-coal" />
    </div>
  );
};

export default GiftPageFooter;
