import { useQuery } from '@tanstack/react-query';

import { parseAuthResult } from '../amplify/amplify.helpers';
import type { CurrentAuthenticatedUser } from '../amplify/amplify.types';
import { Auth } from '../isomorphic-auth/isomorphic-auth';

import { authenticationQueryKey } from './authentication-query-keys';

export type UseQueryAuthenticatedUserProps<T> = { select?: (data: CurrentAuthenticatedUser) => T };

export const useQueryAuthenticatedUser = <T = any>(props?: UseQueryAuthenticatedUserProps<T>) => {
  return useQuery({
    queryKey: authenticationQueryKey.authentication(),
    queryFn: async () => parseAuthResult(await Auth.currentAuthenticatedUser()),
    select: data => (props?.select ? props?.select(data) : (data as T)),
    retry: false,
  });
};
