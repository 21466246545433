import type { FormEvent } from 'react';
import { useCallback, useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import type { Dropin } from 'braintree-web-drop-in';
import { create } from 'braintree-web-drop-in';

import { FormSubmit } from '@components/forms.tsx';

import { doQueryWithApiKey } from '@lib/query';
import type { GiftCodeDetails, ProductPricingWithProduct } from '@pages/checkout/checkout';

import { CHECKOUT_MUTATION } from './checkoutQuery';

import './custom-braintree-dropin-styles.css';

interface BraintreeCheckoutProps {
  registerUser?: {
    email: string;
    password: string;
  };
  productPricing: ProductPricingWithProduct;
  turnstileToken?: string;
  giftCodeDetails?: GiftCodeDetails;
}

enum ButtonColorOption {
  Gold = 'gold',
  Blue = 'blue',
  Silver = 'silver',
  Black = 'black',
  White = 'white',
}

enum ButtonShapeOption {
  Pill = 'pill',
  Rect = 'rect',
}

enum ButtonSizeOption {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  Responsive = 'responsive',
}

enum ButtonLabelOption {
  Checkout = 'checkout',
  Credit = 'credit',
  Pay = 'pay',
  BuyNow = 'buynow',
  PayPal = 'paypal',
}

const BraintreeCheckout = ({
  registerUser,
  productPricing,
  turnstileToken,
  giftCodeDetails,
}: BraintreeCheckoutProps) => {
  const [ settled, setSettled ] = useState(false);
  const navigate = useNavigate();
  const [ dropIn, setDropIn ] = useState<Dropin>();
  const [ submitDisabled, setSubmitDisabled ] = useState(true);
  const [ checkoutMessage, setCheckoutMessage ] = useState('');

  useEffect(() => {
    if (turnstileToken) {
      setSettled(true);
    }
  }, [ turnstileToken ]);

  useEffect(() => {
    (async () => {
      if (!settled || dropIn) {
        return;
      }

      const dropInInstance = create({
        authorization: import.meta.env.VITE_BRAINTREE_TOKENIZATION_KEY as string,
        container: '#dropin-container',
        dataCollector: true,
        card: {
          overrides: {
            styles: {
              input: {
                'letter-spacing': '0.0025rem',
                transform: 'scaleY(0.95)',
              },
              '::-webkit-input-placeholder': {
                color: 'rgba(84, 82, 81, 0.5)',
              },
              ':-moz-placeholder': {
                color: 'rgba(84, 82, 81, 0.5)',
              },
              '::-moz-placeholder': {
                color: 'rgba(84, 82, 81, 0.5)',
              },
              ':-ms-input-placeholder': {
                color: 'rgba(84, 82, 81, 0.5)',
              },
              '::placeholder': {
                color: 'rgba(84, 82, 81, 0.5)',
              },
            },
          },
        },
        paypal: {
          flow: 'checkout',
          buttonStyle: {
            color: ButtonColorOption.Blue,
            shape: ButtonShapeOption.Rect,
            label: ButtonLabelOption.PayPal,
            size: ButtonSizeOption.Medium,
            tagline: false,
          },
        },
        applePay: {
          displayName: 'OMGYES',
          paymentRequest: {
            total: {
              label: 'OMGYES',
              amount: productPricing.amount.toString(),
            },
            requiredBillingContactFields: [ 'postalAddress' ],
            countryCode: 'US',
            currencyCode: 'USD',
            supportedNetworks: [],
            merchantCapabilities: [],
          },
        },
        googlePay: {
          googlePayVersion: 2,
          merchantId: '',
          transactionInfo: {
            totalPriceStatus: 'FINAL',
            totalPrice: productPricing.amount.toString(),
            currencyCode: productPricing.currency,
          },
        },
      });
      setDropIn(await dropInInstance);
      setSubmitDisabled(false);
    })();
    return () => {
      setSubmitDisabled(true);
      dropIn?.teardown(error => {
        if (error) console.error('Error during Braintree dropIn teardown', error);
      });
    };
  }, [ settled ]);

  const onSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      if (!dropIn) return setCheckoutMessage('Error loading payment ui.');
      setSubmitDisabled(true);

      const { nonce: paymentMethodNonce, deviceData } = await dropIn.requestPaymentMethod();

      try {
        await doQueryWithApiKey({
          query: CHECKOUT_MUTATION,
          variables: {
            paymentMethodNonce,
            deviceData,
            productPricingId: productPricing.id,
            registerUser,
            giftCodeDetails,
            turnstileToken,
          },
        });
      } catch {
        setCheckoutMessage('There was an error performing the transaction. Please try again.');
        setSubmitDisabled(false);
        return;
      }
      navigate('/');
    },
    [ dropIn, navigate, turnstileToken ],
  );

  return (
    <>
      <div className="billing-container">
        {checkoutMessage && (
          <div className="py-1" id="checkout-message">
            {checkoutMessage}
          </div>
        )}
        <div id="dropin-container" />
        {dropIn && (
          <FormSubmit
            id="submit-button"
            size="large"
            type="submit"
            color="primary"
            value="Buy Now"
            disabled={submitDisabled}
            onClick={onSubmit}
            className={'px-40px text-18px'}
          />
        )}
      </div>
    </>
  );
};

export default BraintreeCheckout;
