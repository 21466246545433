export enum IdentityProviderApiErrors {
  NOT_AUTHORIZED = 'NotAuthorizedException',
  USER_NOT_FOUND = 'UserNotFoundException',
  PASSWORD_RESET_REQUIRED = 'PasswordResetRequiredException',
  TOO_MANY_REQUESTS = 'TooManyRequestsException',
  ALIAS_EXISTS = 'AliasExistsException',
  CODE_MISMATCH = 'CodeMismatchException',
  EXPIRED_CODE = 'ExpiredCodeException',
  LIMIT_EXCEEDED_EXCEPTION = 'LimitExceededException',
  INVALID_PARAMETER_EXCEPTION = 'InvalidParameterException',
  INVALID_PASSWORD_EXCEPTION = 'InvalidPasswordException',
}

export enum AuthenticationErrors {
  FAILED_TO_SIGN_IN = 'FAILED_TO_SIGN_IN',
  FAILED_TO_VALIDATE_PASSWORD = 'FAILED_TO_VALIDATE_PASSWORD',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  INVALID_PASSWORD = 'INVALID_PASSWORD',
  PASSWORD_RESET_REQUIRED = 'PASSWORD_RESET_REQUIRED',
  LOCKED_ACCOUNT = 'LOCKED_ACCOUNT',
  PASSWORD_REGISTER_ERROR = 'PASSWORD_REGISTER_ERROR',
  TOO_MANY_LOGIN_ATTEMPTS = 'TOO_MANY_LOGIN_ATTEMPTS',
  PASSWORD_CONFIRMATION_DOES_NOT_MATCH = 'PASSWORD_CONFIRMATION_DOES_NOT_MATCH',
  PASSWORD_POLICY_NOT_MET = 'PASSWORD_POLICY_NOT_MET',
  EMAIL_ALREADY_TAKEN = 'EMAIL_ALREADY_TAKEN',
  SESSION_EXPIRED = 'SESSION_EXPIRED',
  GENERIC = 'GENERIC',
  SIGN_OUT_TIMEOUT = 'SIGN_OUT_TIMEOUT',
  LIMIT_EXCEEDED_EXCEPTION = 'LIMIT_EXCEEDED_EXCEPTION',
  INVALID_CODE = 'INVALID_CODE',
  CODE_EXPIRED = 'CODE_EXPIRED',
  INVALID_PARAMETER = 'INVALID_PARAMETER',
}
