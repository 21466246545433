// import { useEffect, useState } from 'react';
// import DataGrid from 'react-data-grid';

// import { LIST_TRANSACTIONS_QUERY } from './transactionsGql';
// import 'react-data-grid/lib/styles.css';
// import {useQueryClient} from "@lib/query.tsx";

const Transactions = () => {
  // const queryClient = useQueryClient()
  // const [trigger, setTrigger] = useState(0);
  // const [transactions, setTransactions] = useState([]);

  // useEffect(() => {
  //   queryClient
  //     .doQuery({
  //       query: LIST_TRANSACTIONS_QUERY,
  //     })
  //     .then((result) => setTransactions(result.listTransactions.map((tx: { user: any; }) => ({...tx, ...(tx.user || {})}))))
  // }, [trigger]);
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setTrigger(Math.random());
  //   }, 5000);
  //   return () => clearInterval(intervalId);
  // }, []);

  // const columns = [
  //   { key: 'id', name: 'Transaction ID' },
  //   { key: 'amount', name: 'Amount' },
  //   { key: 'currency', name: 'Currency' },
  //   { key: 'timestamp', name: 'Timestamp' },
  //   { key: 'createdAt', name: 'Created At' },
  //   { key: 'updatedAt', name: 'Updated At' },
  //   { key: 'email', name: 'Email'}
  // ];

  return (
    <>
      <h2>Transactions</h2>
      {/*<DataGrid*/}
      {/*  className="rdg-light"*/}
      {/*  columns={columns}*/}
      {/*  rows={transactions}*/}
      {/*/>*/}
    </>
  );
};

export default Transactions;
