import { useCallback, useState } from 'react';
import type { FieldValues } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Turnstile } from '@marsidev/react-turnstile';

import AppPageHeader from '@components/common/AppPageHeader.tsx';

import { GiftPageFaq } from './components/GiftPageFaq';
import GiftPageFooter from './components/GiftPageFooter';
import { GiftPageForm } from './components/GiftPageForm/Form';
import { GiftPageHeader } from './components/GiftPageHeader';
import { useGiftPageAnalytics, useGiftPageData, useProductPricing } from './GiftPage.hooks';

const GiftPage = () => {
  const navigate = useNavigate();
  const [ turnstileToken, setTurnstileToken ] = useState<string>();
  const { setValue } = useForm();

  // Use the custom hooks
  useGiftPageAnalytics();
  const { data, isLoading } = useGiftPageData();
  const { productPricing } = useProductPricing(turnstileToken);

  const updateCardImage = (index: number) => {
    if (data?.cardImages[index]?.fields.file.url) {
      setValue('card', data.cardImages[index].fields.file.url);
    }
  };

  const onSubmit = useCallback(
    async ({
      card,
      gift,
      deliveryMethod,
      deliveryDate,
      recipientEmail,
      note,
      senderName,
    }: FieldValues) => {
      // setErrors({})
      try {
        // Navigate to /checkout with gift ID
        navigate(
          `/checkout?ppid=${gift}&giftId=${gift}&recipientEmail=${recipientEmail}&deliveryMethod=${deliveryMethod}&deliveryDate=${deliveryDate.getTime()}&note=${note}&name=${senderName}&card=${card}`,
        );
      } catch (e) {
        // setSubmitErrors(e);
        console.log(e);
      }
    },
    [],
  );

  if (isLoading || !data) return <></>;

  return (
    <>
      <AppPageHeader showLogoLeft={true} />
      <Turnstile
        siteKey={import.meta.env.VITE_CLOUDFLARE_SITEKEY!}
        options={{ size: 'invisible' }}
        onSuccess={setTurnstileToken}
      />
      <GiftPageHeader
        header={data.header}
        subheader={data.subheader}
        headerBackgroundImages={data.headerBackgroundImages}
      />

      <GiftPageForm
        data={data}
        productPricing={productPricing}
        updateCardImage={updateCardImage}
        onSubmit={onSubmit}
      />

      <GiftPageFaq data={data} />

      <div className={'relative gift-page-cta-section'}>
        <div
          className={'absolute w-full h-full z-0'}
          style={{
            background: `radial-gradient(at bottom left, ${data.ctaLeftColor}FF 0%, ${data.ctaLeftColor}00 50%), radial-gradient(at bottom right, ${data.ctaRightColor}FF 0%, ${data.ctaRightColor}00 50%)`,
          }}
        />

        <div className={'gift-x-padding'}>
          <div className={'gift-max1040-container '}>
            <div className={'gift-page-cta-headline z-10'}>{data.ctaHeadline}</div>

            <div className={'gift-page-cta-description z-10'}>{data.ctaDescription}</div>

            <button className={'gift-page-cta-button z-10'}>
              <a href={`${import.meta.env.VITE_OMGYES_SERVER_URL}/join/pricing`}>
                {data.ctaButtonText}
              </a>
            </button>
          </div>
        </div>
      </div>

      <GiftPageFooter />
    </>
  );
};

export default GiftPage;
