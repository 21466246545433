import React from 'react';

import { cn } from '@lib/utils';

import FormLabel from './FormLabel';
import InputError from './InputError';

interface FormTextAreaProps {
  value?: string | number | readonly string[];
  onChange: (value: string) => void;
  placeholder?: string;
  rows?: number;
  className?: string;
  isError?: boolean;
  ref?: React.Ref<HTMLTextAreaElement>;
}

export const FormTextArea = React.forwardRef<HTMLTextAreaElement, FormTextAreaProps>(
  ({ value, onChange, placeholder = '', rows = 4, className, isError }, ref) => {
    return (
      <textarea
        value={value}
        onChange={e => onChange(e.target.value)}
        placeholder={placeholder}
        rows={rows}
        className={cn(
          'w-full px-4 py-2 text-gray-700 bg-white border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none',
          className,
        )}
        ref={ref}
        aria-invalid={isError}
      />
    );
  },
);

FormTextArea.displayName = 'FormTextArea';

interface FormTextAreaWrapperProps {
  error?: { message?: string };
  defaultErrorMessage?: string;
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  errorClassName?: string;
  register: () => Record<string, any>;
  onChange: (value: string) => void;
  name?: string;
  placeholder?: string;
  label?: string;
}

const FormTextAreaWrapper = React.forwardRef<HTMLTextAreaElement, FormTextAreaWrapperProps>(
  (
    {
      error,
      defaultErrorMessage,
      className,
      labelClassName,
      inputClassName,
      errorClassName,
      register,
      onChange,
      ...props
    }: FormTextAreaWrapperProps,
    ref,
  ) => (
    <div className={className || 'w-full'}>
      <FormLabel className={labelClassName} htmlFor={props.name}>
        {props.label}
      </FormLabel>
      <div className="relative w-full h-full">
        <div className="relative w-full">
          <FormTextArea
            className={inputClassName}
            isError={!!error}
            aria-invalid={!!error}
            {...props}
            {...register()}
            onChange={onChange}
            ref={ref}
          />
        </div>
      </div>
      <InputError className={errorClassName} hidden={!(error?.message || defaultErrorMessage)}>
        {error?.message || defaultErrorMessage || ''}
      </InputError>
    </div>
  ),
);

FormTextAreaWrapper.displayName = 'FormTextAreaWrapper';
export default FormTextAreaWrapper;
