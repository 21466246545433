import { useAuthenticatedUser } from '@lib/authentication';

const WhoAmIPage = () => {
  const { authenticatedUser } = useAuthenticatedUser();

  return (
    <>
      <div className={'p-20px'}>
        <div>JWT</div>
        <pre>{JSON.stringify(authenticatedUser, null, 4)}</pre>
      </div>
    </>
  );
};

export default WhoAmIPage;
