import { useCallback, useEffect, useMemo } from 'react';
import { TfiCheck, TfiReload, TfiTrash } from 'react-icons/tfi';
import type { ColDef } from 'ag-grid-community'; // Theme
import { AgGridReact } from 'ag-grid-react'; // React Grid Logic
import _ from 'lodash'; // Theme
import { useRecoilState, useRecoilValue } from 'recoil';
import { v4 as uuid } from 'uuid';

import type { IconProviderStatus } from '@components/table/ActionCellRenderer.tsx';
import ActionCellRenderer, { getActionCellClickHandler } from '@components/table/ActionCellRenderer.tsx';

import { getLanguageName, LANGUAGE_CODES } from '@lib/countries.ts';
import {
  getHighlightChangesCellFormatter,
  getHighlightChangesCellStyler,
  getRowUpdateHandler,
} from '@lib/grid.ts';
import type { EditableLocalization } from '@pages/admin/ProductCatalog/store.ts';
import {
  editProductLocalizationsState,
  productDetails,
  resetEdits,
  selectedProductState,
} from '@pages/admin/ProductCatalog/store.ts';

import 'ag-grid-community/styles/ag-grid.css'; // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css';

const createNewLocalization = (): EditableLocalization => ({
  originalId: '',
  id: '',
  languageCode: '',
  name: '',
  description: '',
  version: '',
});

export default function LocalizationsList() {
  const reset = useRecoilValue(resetEdits);
  const productId = useRecoilValue(selectedProductState);
  const product = useRecoilValue(productDetails(productId));
  const [ rows, setRows ] = useRecoilState(editProductLocalizationsState);

  const byLocalizationId = useMemo(() => {
    const withId = (product?.localizations || []).map(data => ({
      ...data,
      originalId: data.id,
    }));
    return _.keyBy(withId, 'id');
  }, [ product, reset ]);

  const lookupById = useCallback((id: string) => byLocalizationId[id], [ byLocalizationId ]);

  useEffect(() => {
    setRows([ ...Object.values(byLocalizationId), createNewLocalization() ]);
  }, [ byLocalizationId ]);

  const highlightChangesCellStyler = useCallback(getHighlightChangesCellStyler(lookupById), [ lookupById ]);

  const highlightChangesCellFormatter = useCallback(
    getHighlightChangesCellFormatter(lookupById, '-'),
    [ lookupById ],
  );

  const actionHandler = useMemo(
    () => getActionCellClickHandler(lookupById, createNewLocalization, setRows),
    [ lookupById ],
  );

  const columnDefs: ColDef<EditableLocalization>[] = useMemo(
    () => [
      {
        headerName: '',
        field: 'id',
        editable: false,
        cellRenderer: ActionCellRenderer,
        cellRendererParams: {
          icons: (data: EditableLocalization, { isEditing }: IconProviderStatus) =>
            _.merge(
              data.id && { delete: TfiTrash },
              data.originalId &&
                !_.isEqual(data, lookupById(data.originalId)) && { revert: TfiReload },
              isEditing && { done: TfiCheck },
            ),
          onClick: actionHandler,
        },
        cellStyle: highlightChangesCellStyler,
      },
      {
        headerName: 'Language',
        field: 'languageCode',
        filter: 'agTextColumnFilter',
        valueFormatter: param => {
          const maybeValue = highlightChangesCellFormatter(param) || '';
          const languageName = getLanguageName(maybeValue);
          return languageName ? `${languageName} (${maybeValue})` : maybeValue;
        },
        editable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: LANGUAGE_CODES,
        },
        cellStyle: highlightChangesCellStyler,
      },
      {
        headerName: 'Product Name',
        field: 'name',
        filter: 'agTextColumnFilter',
        editable: true,
        cellStyle: highlightChangesCellStyler,
        valueFormatter: highlightChangesCellFormatter,
      },
      {
        headerName: 'Description',
        field: 'description',
        filter: 'agTextColumnFilter',
        editable: true,
        cellStyle: highlightChangesCellStyler,
        valueFormatter: highlightChangesCellFormatter,
      },
      {
        headerName: 'Exp. Version',
        field: 'version',
        filter: 'agTextColumnFilter',
        editable: true,
        cellStyle: highlightChangesCellStyler,
        valueFormatter: highlightChangesCellFormatter,
      },
    ],
    [
      lookupById, actionHandler, highlightChangesCellStyler, highlightChangesCellFormatter, 
    ],
  );

  const onRowEdit = useMemo(
    () =>
      getRowUpdateHandler(
        lookupById,
        [ 'languageCode', 'version' ],
        createNewLocalization,
        setRows,
        ({ data }) => data.id,
        uuid,
      ),
    [],
  );

  return (
    <div className="ag-theme-quartz w-full h-full">
      {productId ? (
        <AgGridReact
          getRowId={({ data }) => data.id}
          autoSizeStrategy={{ type: 'fitCellContents' }}
          editType="fullRow"
          onRowValueChanged={onRowEdit}
          onRowEditingStarted={({ api, node }) => {
            api.refreshCells({ rowNodes: [ node ], columns: [ 'id' ], force: true });
          }}
          // @ts-ignore
          columnDefs={columnDefs}
          rowData={rows.map(row => ({ ...row }))}
        />
      ) : (
        'Select a product in the product list'
      )}
    </div>
  );
}
